// import React, { useState, useEffect, useRef } from 'react';
// import Top from './Top';
// import { MapContainer, TileLayer, Marker, Polyline, useMap } from 'react-leaflet';
// import 'leaflet/dist/leaflet.css';
// import L from 'leaflet';
// import axios from 'axios';
// import './Modal.css'; // Include your modal styles
// import frogfootConfig from './frogfootConfig'; // Google API config for autocomplete
// import { Line } from 'react-chartjs-2'; // Chart.js Line chart for elevation profile
// import 'chart.js/auto'; // Auto registration of charts
// import './Loader.css';

// // Add this after your imports in `Wireless.js`

// const logSearch = async (searchType, coordinates) => {
//     try {
//         const response = await fetch('https://demo.smartmap.world/api/log-search', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({
//                 searchType: searchType,
//                 coordinates: JSON.stringify(coordinates),
//             }),
//         });

//         if (!response.ok) {
//             throw new Error('Failed to log search');
//         }

//         const data = await response.json();
//         console.log('Search logged:', data);
//     } catch (error) {
//         console.error('Error logging search:', error);
//     }
// };

  

// // Constants for initial map setup and available frequency options
// const startingCoords = { lat: -32.902398200648506,  lng: 17.99857779057672 }; // Initial map center coordinates
// const startingOfficeLogo = './markersm.png'; // Marker icon for the user's location
// // const loaderImage = './loader.gif'; // Path to the loader GIF
// const FREQUENCY_OPTIONS = [
//     { value: '0', label: 'No Radio Calculation' },
//     { value: '2Ghz', label: '2 GHz' },
//     { value: '5Ghz', label: '5 GHz' },
//     { value: '11Ghz', label: '11 GHz' },
//     { value: '17Ghz', label: '17 GHz' },
//     { value: '24Ghz', label: '24 GHz' },
//     { value: '60Ghz', label: '60 GHz' },
//     { value: '80Ghz', label: '80 GHz' },
// ];

// // Calculate Fresnel Zone Radius
// function calculateFresnelZoneRadius(n, frequency, d1, d2, totalDistance) {
//     const speedOfLight = 299792458; // Speed of light in meters/second
//     const wavelength = speedOfLight / (frequency * 1e9); // Convert GHz to Hz
//     const fresnelRadius = Math.sqrt((n * wavelength * d1 * d2) / (d1 + d2));
//     console.log(`Fresnel Zone Calculation: n=${n}, frequency=${frequency}GHz, d1=${d1}, d2=${d2}, totalDistance=${totalDistance}, fresnelRadius=${fresnelRadius}`);
//     return fresnelRadius;
// }

// // Update map center when latLng changes
// function UpdateMapCenter({ latLng }) {
//     const map = useMap();
//     useEffect(() => {
//         map.setView([latLng.lat, latLng.lng], 13); // Zoom level set to 15
//     }, [latLng, map]);
//     return null;
// }

// // Logo attribution at the bottom-right corner of the map
// function CustomAttribution() {
//     const map = useMap();

//     useEffect(() => {
//         const logoControl = L.control({ position: 'bottomright' });
//         logoControl.onAdd = function () {
//             const div = L.DomUtil.create('div', 'custom-attribution');
//             div.innerHTML = `<img src="./smartmaplogotext.png" alt="Logo" style="height: 40px;"/>`;
//             return div;
//         };
//         logoControl.addTo(map);
//         return () => {
//             map.removeControl(logoControl);
//         };
//     }, [map]);

//     return null;
// }

// function Wireless() {
//     const [loading, setLoading] = useState(false); // Loading state for preloader
//     const [address, setAddress] = useState(''); // Input address
//     const [coordinates, setCoordinates] = useState(''); // Coordinates input
//     const [latLng, setLatLng] = useState(startingCoords); // Current latLng of the map center
//     const [maxDistance, setMaxDistance] = useState(5000); // Max distance for station filtering
//     const [frequency, setFrequency] = useState('0'); // Selected frequency option
//     const [antennaHeight, setAntennaHeight] = useState(6); // Default antenna height in meters
//     const [baseStations, setBaseStations] = useState([]); // List of all base stations
//     const [feasibleStations, setFeasibleStations] = useState([]); // Filtered feasible stations
//     const [showModal, setShowModal] = useState(false); // Modal visibility state
//     const [selectedStation, setSelectedStation] = useState(null); // Selected station for modal display
//     const [addressSelected, setAddressSelected] = useState(false); // Tracks if an address was selected via autocomplete
//     const [elevationProfileData, setElevationProfileData] = useState(null); // Data for displaying elevation profile chart
//     const [noResults, setNoResults] = useState(false); // Track no results found
//     const chartRef = useRef(null); // Reference to the elevation chart
//     const [originAddress, setOriginAddress] = useState(''); // Track the origin address
//     const autocompleteRef = useRef(null); // Reference for Google Maps autocomplete input

//     useEffect(() => {
//         loadGoogleMapsScript(); // Load Google Maps script for address autocomplete
//         fetchBaseStations(); // Fetch all available base stations
//     }, []);

//     const loadGoogleMapsScript = () => {
//         const script = document.createElement('script');
//         script.src = `https://maps.googleapis.com/maps/api/js?key=${frogfootConfig.googleApiKey}&libraries=places`;
//         script.async = true;
//         script.defer = true;
//         script.onload = initAutocomplete;
//         document.head.appendChild(script);
//     };

//     const initAutocomplete = () => {
//         const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current);
//         autocomplete.addListener('place_changed', () => {
//             const place = autocomplete.getPlace();
//             if (place.geometry) {
//                 const lat = place.geometry.location.lat();
//                 const lng = place.geometry.location.lng();
//                 setCoordinates(`${lat},${lng}`);
//                 setLatLng({ lat, lng }); // Update map center
//                 setAddress(place.formatted_address);
//                 setAddressSelected(true); // Trigger feasibility check
    
//                 // Log the search after the place is selected
//                 logSearch(`${lat},${lng}`, 'Wireless');
//             } else {
//                 console.error("No geometry found for the selected place.");
//             }
//         });
//     };
    

//     // const fetchBaseStations = async () => {
//     //     setLoading(true); // Show loader during fetch
//     //     try {
//     //         const response = await axios.get('/api/get-base-points');
//     //         setBaseStations(response.data);
//     //     } catch (error) {
//     //         console.error('Error fetching base stations:', error);
//     //     } finally {
//     //         setLoading(false); // Hide loader after fetch
//     //     }
//     // };
//     const fetchBaseStations = async () => {
//         setLoading(true); // Show loader during fetch
//         try {
//           const response = await axios.get('/api/get-base-points');
//           setBaseStations(response.data);
//         } catch (error) {
//           console.error('Error fetching base stations:', error);
//         } finally {
//           setLoading(false); // Hide loader after fetch
//         }
//       };
      

//     useEffect(() => {
//         if (addressSelected) {
//             const [lat, lng] = coordinates.split(',').map(coord => parseFloat(coord.trim()));
//             checkFeasibilityAndElevation(lat, lng);
//             setAddressSelected(false); // Reset state after check
//         }
//     }, [addressSelected, coordinates]);

//     const logSearch = async (coordinates, searchType) => {
//         try {
//             await axios.post('/api/log-search', {
//                 searchType, // 'Wireless' or 'Fibre'
//                 coordinates
//             });
//             console.log('Search logged successfully');
//         } catch (error) {
//             console.error('Error logging search:', error);
//         }
//     };
    
//     const handleSearch = () => {
//         const [lat, lng] = coordinates.split(',').map(coord => parseFloat(coord.trim()));
//         if (!isNaN(lat) && !isNaN(lng)) {
//             setLatLng({ lat, lng });
//             checkFeasibilityAndElevation(lat, lng);
    
//             // Log the search after it's performed
//             logSearch('Wireless', coordinates);
//         } else {
//             alert('Please enter valid coordinates.');
//         }
//     };
    
//     const handlePrintResults = (station, chartRef, originAddress, originCoords) => {
//         const chartImage = chartRef.current.toBase64Image();
    
//         axios.post('/api/generate-pdf', {
//             station,
//             chartImage,
//             originAddress,
//             originCoords,
//         }, {
//             responseType: 'blob',
//         }).then((response) => {
//             const blob = new Blob([response.data], { type: 'application/pdf' });
//             const url = window.URL.createObjectURL(blob);
//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', `${station.name}_Report.pdf`);
//             document.body.appendChild(link);
//             link.click();
//         }).catch((error) => {
//             console.error('Error generating PDF:', error);
//         });
//     };


//     // const checkFeasibilityAndElevation = async (lat, lng) => {
//     //     setLoading(true); // Show loader while checking feasibility
//     //     setNoResults(false); // Reset no results state
//     //     let finalFeasibleStations = [];

//     //     const feasible = baseStations.filter(station => {
//     //         const stationLat = parseFloat(station.latitude);
//     //         const stationLng = parseFloat(station.longitude);
//     //         const distance = calculateDistance(lat, lng, stationLat, stationLng);
//     //         return distance <= maxDistance;
//     //     });

//     //     if (feasible.length === 0) {
//     //         setNoResults(true); // No feasible stations found
//     //     } else {
//     //         for (const station of feasible) {
//     //             const elevationData = await getElevationData({ lat, lng }, station);

//     //             if (elevationData && elevationData.length > 0) {
//     //                 const originTotalHeight = elevationData[0].elevation + parseFloat(antennaHeight);
//     //                 const destinationTotalHeight = elevationData[elevationData.length - 1].elevation + parseFloat(station.height || 6);

//     //                 if (checkFeasibilityFromChartData(elevationData, originTotalHeight, destinationTotalHeight)) {
//     //                     station.elevationProfile = elevationData;
//     //                     station.totalDistance = calculateDistance(lat, lng, station.latitude, station.longitude);
//     //                     station.originTotalHeight = originTotalHeight;
//     //                     station.destinationTotalHeight = destinationTotalHeight;

//     //                     finalFeasibleStations.push(station);
//     //                 }
//     //             }
//     //         }
//     //     }

//     //     setFeasibleStations(finalFeasibleStations);
//     //     setLoading(false); // Hide loader after checking feasibility
//     // };

//     const checkFeasibilityAndElevation = async (lat, lng) => {
//         setLoading(true); // Show loader while checking feasibility
//         setNoResults(false); // Reset no results state
//         let finalFeasibleStations = [];
      
//         const feasible = baseStations.filter((station) => {
//           const stationLat = parseFloat(station.latitude);
//           const stationLng = parseFloat(station.longitude);
//           const distance = calculateDistance(lat, lng, stationLat, stationLng);
      
//           // Use station's range if defined, else default to user's maxDistance
//           const stationRange = station.range || maxDistance;
//           const effectiveRange = Math.min(stationRange, maxDistance);
      
//           // Check distance
//           if (distance > effectiveRange) {
//             return false;
//           }
      
//           // Calculate bearing from base station to client
//           const bearing = calculateBearing(
//             stationLat,
//             stationLng,
//             lat,
//             lng
//           );
      
//           // Get station's azimuth and sector_angle
//           const stationAzimuth = parseFloat(station.azimuth) || 0; // Default to 0
//           const sectorAngle = parseFloat(station.sector_angle) || 360; // Default to 360
      
//           // Calculate half of the sector angle
//           const halfSector = sectorAngle / 2;
      
//           // Determine min and max angles for sector coverage
//           let minAngle = (stationAzimuth - halfSector + 360) % 360;
//           let maxAngle = (stationAzimuth + halfSector) % 360;
      
//           let inSector = false;
//           if (minAngle <= maxAngle) {
//             // Normal case
//             inSector = bearing >= minAngle && bearing <= maxAngle;
//           } else {
//             // Sector wraps around 0 degrees
//             inSector = bearing >= minAngle || bearing <= maxAngle;
//           }
      
//           // Return true if client is within sector coverage, false otherwise
//           return inSector;
//         });
      
//         if (feasible.length === 0) {
//           setNoResults(true); // No feasible stations found
//           setLoading(false); // Hide loader after checking feasibility
//           return;
//         }
      
//         for (const station of feasible) {
//           const elevationData = await getElevationData({ lat, lng }, station);
      
//           if (elevationData && elevationData.length > 0) {
//             const originTotalHeight =
//               elevationData[0].elevation + parseFloat(antennaHeight);
//             const destinationTotalHeight =
//               elevationData[elevationData.length - 1].elevation +
//               parseFloat(station.height || 6);
      
//             if (
//               checkFeasibilityFromChartData(
//                 elevationData,
//                 originTotalHeight,
//                 destinationTotalHeight
//               )
//             ) {
//               station.elevationProfile = elevationData;
//               station.totalDistance = calculateDistance(
//                 lat,
//                 lng,
//                 station.latitude,
//                 station.longitude
//               );
//               station.originTotalHeight = originTotalHeight;
//               station.destinationTotalHeight = destinationTotalHeight;
      
//               finalFeasibleStations.push(station);
//             }
//           }
//         }
      
//         setFeasibleStations(finalFeasibleStations);
//         setLoading(false); // Hide loader after checking feasibility
//       };
      

//     const checkFeasibilityFromChartData = (elevationData, originTotalHeight, destinationTotalHeight) => {
//         const losLine = elevationData.map((_, index) => {
//             const proportion = index / (elevationData.length - 1);
//             return originTotalHeight + proportion * (destinationTotalHeight - originTotalHeight);
//         });

//         for (let i = 0; i < elevationData.length; i++) {
//             if (elevationData[i].elevation >= losLine[i]) {
//                 console.log(`LOS blocked at point ${i}: Elevation = ${elevationData[i].elevation}, LOS line = ${losLine[i]}`);
//                 return false;
//             }
//         }

//         console.log("LOS is clear based on chart data.");
//         return true;
//     };

//     const getElevationData = async (origin, destination) => {
//         const totalDistance = calculateDistance(origin.lat, origin.lng, destination.latitude, destination.longitude);
//         const numPoints = Math.ceil(totalDistance / 35);
//         const points = generateIntermediatePoints(origin, destination, numPoints);

//         try {
//             const response = await axios.post('/api/get-elevation-data', { points });
//             return response.data;
//         } catch (error) {
//             console.error('Error fetching elevation data:', error);
//             return [];
//         }
//     };

//     const generateIntermediatePoints = (origin, destination, numPoints) => {
//         const points = [];
//         const latStep = (destination.latitude - origin.lat) / numPoints;
//         const lngStep = (destination.longitude - origin.lng) / numPoints;

//         for (let i = 0; i <= numPoints; i++) {
//             const lat = origin.lat + latStep * i;
//             const lng = origin.lng + lngStep * i;
//             points.push({ lat, lng });
//         }

//         return points;
//     };

//     const calculateDistance = (lat1, lon1, lat2, lon2) => {
//         const R = 6371000;
//         const dLat = ((lat2 - lat1) * Math.PI) / 180;
//         const dLon = ((lon2 - lon1) * Math.PI) / 180;
//         const a = Math.sin(dLat / 2) ** 2 + Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) ** 2;
//         const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//         return R * c;
//     };

   
//     // const calculateBearing = (lat1, lon1, lat2, lon2) => {
//     //     const toRadians = (degree) => (degree * Math.PI) / 180;
//     //     const toDegrees = (radian) => (radian * 180) / Math.PI;

//     //     const dLon = toRadians(lon2 - lon1);
//     //     const y = Math.sin(dLon) * Math.cos(toRadians(lat2));
//     //     const x = Math.cos(toRadians(lat1)) * Math.sin(toRadians(lat2)) - Math.sin(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.cos(dLon);
//     //     const bearing = toDegrees(Math.atan2(y, x));

//     //     return (bearing + 360) % 360;
//     // };
//     const calculateBearing = (lat1, lon1, lat2, lon2) => {
//         const toRadians = (degree) => (degree * Math.PI) / 180;
//         const toDegrees = (radian) => (radian * 180) / Math.PI;
      
//         const dLon = toRadians(lon2 - lon1);
//         const y = Math.sin(dLon) * Math.cos(toRadians(lat2));
//         const x =
//           Math.cos(toRadians(lat1)) * Math.sin(toRadians(lat2)) -
//           Math.sin(toRadians(lat1)) *
//             Math.cos(toRadians(lat2)) *
//             Math.cos(dLon);
//         const bearing = toDegrees(Math.atan2(y, x));
      
//         return (bearing + 360) % 360;
//       };
      

//     // const handleBaseStationClick = (station) => {
//     //     const bearing = calculateBearing(latLng.lat, latLng.lng, station.latitude, station.longitude);
//     //     setSelectedStation({
//     //         ...station,
//     //         originCoords: latLng,
//     //         distance: calculateDistance(latLng.lat, latLng.lng, station.latitude, station.longitude).toFixed(2),
//     //         bearing: bearing.toFixed(2),
//     //     });
//     //     setOriginAddress(address); // Set the origin address
//     //     setShowModal(true);
//     // };
//     const handleBaseStationClick = (station) => {
//         // Calculate distance and bearing
//         const distance = calculateDistance(
//           latLng.lat,
//           latLng.lng,
//           station.latitude,
//           station.longitude
//         ).toFixed(2);
      
//         const bearing = calculateBearing(
//           station.latitude,
//           station.longitude,
//           latLng.lat,
//           latLng.lng
//         ).toFixed(2); // Bearing from base station to client
      
//         setSelectedStation({
//           ...station,
//           originCoords: latLng,
//           distance,
//           bearing,
//         });
//         setOriginAddress(address); // Set the origin address
//         setShowModal(true);
//       };
      
    
//     const closeModal = () => {
//         setShowModal(false);
//     };

//     const handleClearForm = () => {
//         setAddress('');
//         setCoordinates('');
//         setLatLng(startingCoords);
//         setMaxDistance(5000);
//         setFrequency('0');
//         setAntennaHeight(6);
//         setFeasibleStations([]);
//         setElevationProfileData(null);
//     };

//     const markerIcon = new L.Icon({
//         iconUrl: startingOfficeLogo,
//         iconSize: [25, 38],
//         iconAnchor: [12, 38],
//     });

//     return (
//         <div style={styles.pageContainer}>
//             <Top />
//             <div id="map-container" style={styles.mapWrapper}>
//                 <MapContainer
//                     center={[latLng.lat, latLng.lng]}
//                     zoom={16}
//                     scrollWheelZoom={true}
//                     style={styles.mapContainer}
//                     attributionControl={false}
//                 >
//                     <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" />
//                     <Marker position={[latLng.lat, latLng.lng]} icon={markerIcon} />
//                     <UpdateMapCenter latLng={latLng} />
//                     <CustomAttribution />
//                     <div style={styles.searchContainer}>
//                     <div style={styles.inputGroup}>
//                             <label>Select Equipment Frequency</label>
//                             <select
//                                 value={frequency}
//                                 onChange={(e) => setFrequency(e.target.value)}
//                                 style={styles.inputBox}
//                             >
//                                 {FREQUENCY_OPTIONS.map((option) => (
//                                     <option key={option.value} value={option.value}>
//                                         {option.label}
//                                     </option>
//                                 ))}
//                             </select>
//                         </div>
//                         <div style={styles.inputGroup}>
//                             <label>Installed Antenna Height in Meters</label>
//                             <input
//                                 type="number"
//                                 value={antennaHeight}
//                                 onChange={(e) => setAntennaHeight(e.target.value)}
//                                 style={styles.inputBox}
//                             />
//                             <div style={styles.inputGroup}>
//                             <label>Maximum Distance of Link in Meters</label>
//                             <input
//                                 type="number"
//                                 value={maxDistance}
//                                 onChange={(e) => setMaxDistance(e.target.value)}
//                                 style={styles.inputBox}
//                             />
//                         </div>
//                         <label>Search for Address</label>
//                         <input
//                             ref={autocompleteRef}
//                             type="text"
//                             value={address}
//                             onChange={(e) => setAddress(e.target.value)}
//                             placeholder=""
//                             style={styles.inputBox}
//                         />
//                         <div style={styles.inputGroup}>
//                             <label>Enter Coordinates (latitude, longitude)</label>
//                             <input
//                                 type="text"
//                                 value={coordinates}
//                                 onChange={(e) => setCoordinates(e.target.value)}
//                                 //placeholder="Enter coordinates"
//                                 style={styles.inputBox}
//                             />
//                         </div>
                        
//                         </div>
                        
//                         <button onClick={handleSearch} style={styles.submitButton}>
//                             Search
//                         </button>
//                         <button onClick={handleClearForm} style={styles.clearButton}>
//                             Reset Search
//                         </button>
//                     </div>
//                     {feasibleStations.map((station, index) => (
//                         <Marker
//                             key={index}
//                             position={[parseFloat(station.latitude), parseFloat(station.longitude)]}
//                             icon={markerIcon}
//                             eventHandlers={{
//                                 click: () => handleBaseStationClick(station),
//                             }}
//                         />
//                     ))}
//                     {feasibleStations.map((station, index) => (
//                         <Polyline
//                             key={index}
//                             positions={[
//                                 [latLng.lat, latLng.lng],
//                                 [parseFloat(station.latitude), parseFloat(station.longitude)],
//                             ]}
//                             color="red"
//                             weight={1}
//                         />
//                     ))}
//                 </MapContainer>
//             </div>

                        
//             {loading && (
//                 <div className="loader-overlay">
//                     <div className="loader"></div>
//                 </div>
//             )}


//             {/* No Results Modal */}
//             {noResults && (
//                 <div style={styles.noResultsOverlay} onClick={() => setNoResults(false)}>
//                     <div style={styles.noResultsModal} onClick={(e) => e.stopPropagation()}>
//                         <h3>No Results Found</h3>
//                         <p>Please try different coordinates or adjust your search parameters.</p>
//                         <button onClick={() => setNoResults(false)} style={styles.closeButton}>
//                             Close
//                         </button>
//                     </div>
//                 </div>
//             )}

//             {/* Modal styling and content */}
//             {showModal && selectedStation && (
//                 <div className="modal-overlay" onClick={closeModal}>
//                     <div className="modal-content" style={styles.modal} onClick={(e) => e.stopPropagation()}>
//                         <div style={styles.modalTop}>
//                             <h3 style={styles.baseStationTitle}>Base Station: {selectedStation.name}</h3>
//                             {/* <div style={styles.stationInfo}>
//                                 <p>
//                                     Origin Coordinates: {selectedStation.originCoords.lat.toFixed(6)},{' '}
//                                     {selectedStation.originCoords.lng.toFixed(6)}
//                                 </p>
//                                 <p>Distance to Base: {selectedStation.distance} meters</p>
//                                 <p>Bearing: {selectedStation.bearing}°</p>
//                             </div> */}
//                             <div style={styles.stationInfo}>
//                             <p>
//                                 Origin Coordinates: {selectedStation.originCoords.lat.toFixed(6)},{' '}
//                                 {selectedStation.originCoords.lng.toFixed(6)}
//                             </p>
//                             <p>Distance to Base: {selectedStation.distance} meters</p>
//                             <p>Bearing from Base to Client: {selectedStation.bearing}°</p>
//                             <p>Sector Azimuth: {selectedStation.azimuth}°</p>
//                             <p>Sector Angle: {selectedStation.sector_angle}°</p>
//                             <p>Base Station Range: {selectedStation.range} meters</p>
//                             </div>

//                         </div>
//                         <div style={styles.modalChart}>
//                             <h2>Elevation Profile</h2>
//                             {selectedStation.elevationProfile && selectedStation.totalDistance ? (
//                                 <div style={{ width: '100%', height: 'auto' }}>
//                                     <Line
//                                         ref={chartRef} // Attach the ref here
//                                         data={{
//                                             labels: selectedStation.elevationProfile.map((_, index) => {
//                                                 const proportion = index / (selectedStation.elevationProfile.length - 1);
//                                                 return (proportion * selectedStation.totalDistance).toFixed(2);
//                                             }),
//                                             datasets: [
//                                                 {
//                                                     label: 'Elevation Profile',
//                                                     data: selectedStation.elevationProfile.map((point, index) => {
//                                                         console.log(`Elevation at point ${index}: ${point.elevation}`);
//                                                         if (index === 0) {
//                                                             return selectedStation.originTotalHeight;
//                                                         } else if (index === selectedStation.elevationProfile.length - 1) {
//                                                             return selectedStation.destinationTotalHeight;
//                                                         }
//                                                         return point.elevation;
//                                                     }),
//                                                     borderColor: '#61acb4',
//                                                     borderWidth: 2,
//                                                     pointRadius: 0,
//                                                     tension: 0.4,
//                                                     fill: 'origin',
//                                                     backgroundColor: 'rgba(97, 172, 180, 0.5)',
//                                                 },
//                                                 {
//                                                     label: 'LOS Line',
//                                                     data: selectedStation.elevationProfile.map((_, index) => {
//                                                         const proportion = index / (selectedStation.elevationProfile.length - 1);
//                                                         const losValue = selectedStation.originTotalHeight + proportion * (selectedStation.destinationTotalHeight - selectedStation.originTotalHeight);
//                                                         console.log(`LOS line at point ${index}: ${losValue}`);
//                                                         return losValue;
//                                                     }),
//                                                     borderColor: 'red',
//                                                     borderWidth: 1,
//                                                     pointRadius: 0,
//                                                     tension: 0.4,
//                                                 },
//                                                 {
//                                                     label: 'First Fresnel Zone',
//                                                     data: selectedStation.elevationProfile.map((_, index) => {
//                                                         const proportion = index / (selectedStation.elevationProfile.length - 1);
//                                                         const distanceFromObserver = proportion * selectedStation.totalDistance;
//                                                         const distanceToBase = selectedStation.totalDistance - distanceFromObserver;

//                                                         const fresnelRadius = calculateFresnelZoneRadius(1, parseFloat(frequency.replace('Ghz', '')), distanceFromObserver, distanceToBase, selectedStation.totalDistance);
//                                                         console.log(`First Fresnel radius at point ${index}: ${fresnelRadius}`);

//                                                         const fresnelValue = selectedStation.originTotalHeight + proportion * (selectedStation.destinationTotalHeight - selectedStation.originTotalHeight) - fresnelRadius;
//                                                         console.log(`First Fresnel zone value at point ${index}: ${fresnelValue}`);
//                                                         return fresnelValue;
//                                                     }),
//                                                     borderColor: 'rgba(50, 205, 50, 0.5)',
//                                                     borderWidth: 1,
//                                                     pointRadius: 0,
//                                                     tension: 0.4,
//                                                 },
//                                                 {
//                                                     label: 'Second Fresnel Zone',
//                                                     data: selectedStation.elevationProfile.map((_, index) => {
//                                                         const proportion = index / (selectedStation.elevationProfile.length - 1);
//                                                         const distanceFromObserver = proportion * selectedStation.totalDistance;
//                                                         const distanceToBase = selectedStation.totalDistance - distanceFromObserver;

//                                                         const fresnelRadius = calculateFresnelZoneRadius(2, parseFloat(frequency.replace('Ghz', '')), distanceFromObserver, distanceToBase, selectedStation.totalDistance);
//                                                         console.log(`Second Fresnel radius at point ${index}: ${fresnelRadius}`);

//                                                         const fresnelValue = selectedStation.originTotalHeight + proportion * (selectedStation.destinationTotalHeight - selectedStation.originTotalHeight) - fresnelRadius;
//                                                         console.log(`Second Fresnel zone value at point ${index}: ${fresnelValue}`);
//                                                         return fresnelValue;
//                                                     }),
//                                                     borderColor: 'rgba(30, 144, 255, 0.5)',
//                                                     borderWidth: 1,
//                                                     pointRadius: 0,
//                                                     tension: 0.4,
//                                                 },
//                                             ],
//                                         }}
//                                         options={{
//                                             responsive: true, // Ensure chart resizes with the modal
//                                             maintainAspectRatio: false, // Disable aspect ratio to allow flexible height
//                                             scales: {
//                                                 x: {
//                                                     title: { display: true, text: 'Distance from Origin (m)' },
//                                                     ticks: { maxTicksLimit: 40 },
//                                                 },
//                                                 y: {
//                                                     title: { display: true, text: 'Elevation (m)' },
//                                                     // min: 0,
//                                                     min: Math.min(...selectedStation.elevationProfile.map(point => point.elevation)) - 20, // Dynamically set Y-axis minimum
//                                                 },
//                                             },
//                                             layout: {
//                                                 padding: {
//                                                     top: 10,
//                                                     left: 10,
//                                                     right: 10,
//                                                     bottom: 10,
//                                                 },
//                                             },
//                                         }}
//                                         height={300} // Allow parent container to control height
//                                         width={null}  // Allow parent container to control width
//                                     />
//                                 </div>
//                             ) : (
//                                 <p>No elevation profile available</p>
//                             )}
//                         </div>

//                         <div style={styles.printButtonContainer}>
//                             <button style={styles.printButton} onClick={() => handlePrintResults(selectedStation, chartRef, originAddress, latLng)}>
//                                 Save and Print Report
//                             </button>
//                         </div>


                        
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// }

// // Styles for the Wireless component
// const styles = {
//     pageContainer: {
//         height: '100vh',
//         display: 'flex',
//         flexDirection: 'column',
//         overflow: 'hidden',
//     },
//     mapWrapper: {
//         width: '100%',
//         height: '100%',
//         borderRadius: 'inherit',
//         overflow: 'hidden',
//     },
//     mapContainer: {
//         width: '100%',
//         height: '100%',
//     },
//     searchContainer: {
//         position: 'absolute',
//         top: '15px',
//         left: '55px',
//         backgroundColor: 'white',
//         padding: '12px',
//         borderRadius: '12px',
//         boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
//         zIndex: 1000,
//         width: '25%',
//     },
//     inputBox: {
//         width: '90%',
//         padding: '10px',
//         borderRadius: '5px',
//         border: '1px solid slategray',
//         marginBottom: '10px',
//     },
//     inputGroup: {
//         marginBottom: '10px',
//     },
//     submitButton: {
//         padding: '10px',
//         borderRadius: '6px',
//         backgroundColor: '#61acb4',
//         color: 'white',
//         cursor: 'pointer',
//         border: 'none',
//         fontSize: '1rem', // Use rem units for scalability
//     },
//     clearButton: {
//         padding: '10px',
//         borderRadius: '6px',
//         backgroundColor: 'red',
//         color: 'white',
//         cursor: 'pointer',
//         border: 'none',
//         marginLeft: '10px',
//         fontSize: '1rem', // Use rem units for scalability
//     },
//     modal: {
//         width: '80%', // Use percentage width for responsiveness
//         maxWidth: '1000px', // Set a maximum width
//         height: 'auto',
//         maxHeight: '90vh', // Set a maximum height based on viewport height
//         position: 'fixed',
//         top: '5%',
//         left: '50%',
//         transform: 'translateX(-50%)',
//         backgroundColor: 'white',
//         padding: '20px',
//         boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
//         borderRadius: '12px',
//         zIndex: 2000,
//         textAlign: 'center',
//         overflowY: 'auto', // Enable vertical scrolling if content overflows
//     },
//     modalTop: {
//         width: '100%',
//         textAlign: 'center',
//         marginBottom: '20px',
//     },
//     baseStationTitle: {
//         fontSize: '1.5rem', // Use rem for scalability
//         marginBottom: '10px',
//     },
//     stationInfo: {
//         fontSize: '1rem',
//         margin: '10px 0',
//         display: 'flex',
//         justifyContent: 'space-around',
//         flexWrap: 'wrap', // Allow wrapping on smaller screens
//     },
//     modalChart: {
//         textAlign: 'center',
//         marginTop: '20px',
//         width: '100%', // Ensure chart takes the full width of the modal
//         height: '50vh',
//     },
//     printButtonContainer: {
//         marginTop: '20px',
//         textAlign: 'center',
//     },
//     printButton: {
//         padding: '10px',
//         borderRadius: '6px',
//         backgroundColor: '#61acb4',
//         color: 'white',
//         cursor: 'pointer',
//         border: 'none',
//         fontSize: '1rem', // Responsive font size
//     },
//     noResultsModal: {
//         width: '50%', // Use a smaller width for the no-results modal
//         height: 'auto',
//         position: 'fixed',
//         top: '30%',
//         left: '50%',
//         transform: 'translateX(-50%)',
//         backgroundColor: 'white',
//         padding: '20px',
//         boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)', // Slightly deeper shadow
//         borderRadius: '16px', // Larger rounded corners
//         zIndex: 2000,
//         textAlign: 'center', // Center the content
//     },
//     noResultsOverlay: {
//         position: 'fixed',
//         top: 0,
//         left: 0,
//         width: '100%',
//         height: '100%',
//         backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
//         zIndex: 1999,
//     },
//     closeButton: {
//         padding: '10px 20px',
//         borderRadius: '8px',
//         backgroundColor: '#ff4d4d', // Red button to indicate no results
//         color: 'white',
//         border: 'none',
//         cursor: 'pointer',
//         marginTop: '20px',
//     },
// };


// export default Wireless;


// import React, { useState, useEffect, useRef } from 'react';
// import Top from './Top';
// import { MapContainer, TileLayer, Marker, Polyline, useMap } from 'react-leaflet';
// import 'leaflet/dist/leaflet.css';
// import L from 'leaflet';
// import axios from 'axios';
// import './Modal.css'; // Include your modal styles
// import frogfootConfig from './frogfootConfig'; // Google API config for autocomplete
// import { Line } from 'react-chartjs-2'; // Chart.js Line chart for elevation profile
// import 'chart.js/auto'; // Auto registration of charts
// import './Loader.css';

// // Function to log search activity
// const logSearch = async (searchType, coordinates) => {
//     try {
//         const response = await fetch('https://demo.smartmap.world/api/log-search', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({
//                 searchType: searchType,
//                 coordinates: JSON.stringify(coordinates),
//             }),
//         });

//         if (!response.ok) {
//             throw new Error('Failed to log search');
//         }

//         const data = await response.json();
//         console.log('Search logged:', data);
//     } catch (error) {
//         console.error('Error logging search:', error);
//     }
// };

// // Constants for initial map setup and available frequency options
// const startingCoords = { lat: -32.902398200648506, lng: 17.99857779057672 }; // Initial map center coordinates
// const startingOfficeLogo = './markersm.png'; // Marker icon for the user's location
// const FREQUENCY_OPTIONS = [
//     { value: '0', label: 'No Radio Calculation' },
//     { value: '2Ghz', label: '2 GHz' },
//     { value: '5Ghz', label: '5 GHz' },
//     { value: '11Ghz', label: '11 GHz' },
//     { value: '17Ghz', label: '17 GHz' },
//     { value: '24Ghz', label: '24 GHz' },
//     { value: '60Ghz', label: '60 GHz' },
//     { value: '80Ghz', label: '80 GHz' },
// ];

// // Calculate Fresnel Zone Radius
// function calculateFresnelZoneRadius(n, frequency, d1, d2, totalDistance) {
//     const speedOfLight = 299792458; // Speed of light in meters/second
//     const wavelength = speedOfLight / (frequency * 1e9); // Convert GHz to Hz
//     const fresnelRadius = Math.sqrt((n * wavelength * d1 * d2) / (d1 + d2));
//     console.log(`Fresnel Zone Calculation: n=${n}, frequency=${frequency}GHz, d1=${d1}, d2=${d2}, totalDistance=${totalDistance}, fresnelRadius=${fresnelRadius}`);
//     return fresnelRadius;
// }

// // Update map center when latLng changes
// function UpdateMapCenter({ latLng }) {
//     const map = useMap();
//     useEffect(() => {
//         map.setView([latLng.lat, latLng.lng], 13); // Zoom level set to 13
//     }, [latLng, map]);
//     return null;
// }

// // Logo attribution at the bottom-right corner of the map
// function CustomAttribution() {
//     const map = useMap();

//     useEffect(() => {
//         const logoControl = L.control({ position: 'bottomright' });
//         logoControl.onAdd = function () {
//             const div = L.DomUtil.create('div', 'custom-attribution');
//             div.innerHTML = `<img src="./smartmaplogotext.png" alt="Logo" style="height: 40px;"/>`;
//             return div;
//         };
//         logoControl.addTo(map);
//         return () => {
//             map.removeControl(logoControl);
//         };
//     }, [map]);

//     return null;
// }

// function Wireless() {
//     const [loading, setLoading] = useState(false); // Loading state for preloader
//     const [address, setAddress] = useState(''); // Input address
//     const [coordinates, setCoordinates] = useState(''); // Coordinates input
//     const [latLng, setLatLng] = useState(startingCoords); // Current latLng of the map center
//     const [maxDistance, setMaxDistance] = useState(5000); // Max distance for station filtering
//     const [frequency, setFrequency] = useState('0'); // Selected frequency option
//     const [antennaHeight, setAntennaHeight] = useState(6); // Default antenna height in meters
//     const [baseStations, setBaseStations] = useState([]); // List of all base stations
//     const [feasibleStations, setFeasibleStations] = useState([]); // Filtered feasible stations
//     const [showModal, setShowModal] = useState(false); // Modal visibility state
//     const [selectedStation, setSelectedStation] = useState(null); // Selected station for modal display
//     const [addressSelected, setAddressSelected] = useState(false); // Tracks if an address was selected via autocomplete
//     const [noResults, setNoResults] = useState(false); // Track no results found
//     const chartRef = useRef(null); // Reference to the elevation chart
//     const [originAddress, setOriginAddress] = useState(''); // Track the origin address
//     const autocompleteRef = useRef(null); // Reference for Google Maps autocomplete input

//     useEffect(() => {
//         loadGoogleMapsScript(); // Load Google Maps script for address autocomplete
//         fetchBaseStations(); // Fetch all available base stations
//     }, []);

//     const loadGoogleMapsScript = () => {
//         const script = document.createElement('script');
//         script.src = `https://maps.googleapis.com/maps/api/js?key=${frogfootConfig.googleApiKey}&libraries=places`;
//         script.async = true;
//         script.defer = true;
//         script.onload = initAutocomplete;
//         document.head.appendChild(script);
//     };

//     const initAutocomplete = () => {
//         const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current);
//         autocomplete.addListener('place_changed', () => {
//             const place = autocomplete.getPlace();
//             if (place.geometry) {
//                 const lat = place.geometry.location.lat();
//                 const lng = place.geometry.location.lng();
//                 setCoordinates(`${lat},${lng}`);
//                 setLatLng({ lat, lng }); // Update map center
//                 setAddress(place.formatted_address);
//                 setAddressSelected(true); // Trigger feasibility check

//                 // Log the search after the place is selected
//                 logSearch('Wireless', `${lat},${lng}`);
//             } else {
//                 console.error("No geometry found for the selected place.");
//             }
//         });
//     };

//     const fetchBaseStations = async () => {
//         setLoading(true); // Show loader during fetch
//         try {
//             const response = await axios.get('/api/get-base-points');
//             setBaseStations(response.data);
//         } catch (error) {
//             console.error('Error fetching base stations:', error);
//         } finally {
//             setLoading(false); // Hide loader after fetch
//         }
//     };

//     useEffect(() => {
//         if (addressSelected) {
//             const [lat, lng] = coordinates.split(',').map((coord) => parseFloat(coord.trim()));
//             checkFeasibilityAndElevation(lat, lng);
//             setAddressSelected(false); // Reset state after check
//         }
//     }, [addressSelected, coordinates]);

//     const handleSearch = () => {
//         const [lat, lng] = coordinates.split(',').map((coord) => parseFloat(coord.trim()));
//         if (!isNaN(lat) && !isNaN(lng)) {
//             setLatLng({ lat, lng });
//             checkFeasibilityAndElevation(lat, lng);

//             // Log the search after it's performed
//             logSearch('Wireless', coordinates);
//         } else {
//             alert('Please enter valid coordinates.');
//         }
//     };

//     const handlePrintResults = (station, chartRef, originAddress, originCoords) => {
//         const chartImage = chartRef.current.toBase64Image();

//         axios
//             .post(
//                 '/api/generate-pdf',
//                 {
//                     station,
//                     chartImage,
//                     originAddress,
//                     originCoords,
//                 },
//                 {
//                     responseType: 'blob',
//                 }
//             )
//             .then((response) => {
//                 const blob = new Blob([response.data], { type: 'application/pdf' });
//                 const url = window.URL.createObjectURL(blob);
//                 const link = document.createElement('a');
//                 link.href = url;
//                 link.setAttribute('download', `${station.name}_Report.pdf`);
//                 document.body.appendChild(link);
//                 link.click();
//             })
//             .catch((error) => {
//                 console.error('Error generating PDF:', error);
//             });
//     };

//     const checkFeasibilityAndElevation = async (lat, lng) => {
//         setLoading(true); // Show loader while checking feasibility
//         setNoResults(false); // Reset no results state
//         let finalFeasibleStations = [];

//         const feasible = baseStations.filter((station) => {
//             const stationLat = parseFloat(station.latitude);
//             const stationLng = parseFloat(station.longitude);
//             const distance = calculateDistance(lat, lng, stationLat, stationLng);

//             // Use station's range if defined; else default to user's maxDistance
//             const stationRange = station.range ? parseFloat(station.range) : maxDistance;
//             const effectiveRange = Math.min(stationRange, maxDistance);

//             // Check distance
//             if (distance > effectiveRange) {
//                 return false;
//             }

//             // Calculate bearing from base station to client
//             const bearing = calculateBearing(stationLat, stationLng, lat, lng);

//             // Get station's azimuth and sector_angle
//             const stationAzimuth = station.azimuth ? parseFloat(station.azimuth) : 0; // Default to 0
//             const sectorAngle = station.sector_angle ? parseFloat(station.sector_angle) : 360; // Default to 360

//             // Check if the client is within the sector
//             const inSector = isInSector(bearing, stationAzimuth, sectorAngle);

//             // Debugging logs
//             console.log(`Station: ${station.name}`);
//             console.log(`Distance: ${distance}`);
//             console.log(`Bearing: ${bearing}`);
//             console.log(`Azimuth: ${stationAzimuth}`);
//             console.log(`Sector Angle: ${sectorAngle}`);
//             console.log(`In Sector: ${inSector}`);

//             return inSector;
//         });

//         if (feasible.length === 0) {
//             setNoResults(true); // No feasible stations found
//             setLoading(false); // Hide loader after checking feasibility
//             return;
//         }

//         for (const station of feasible) {
//             const elevationData = await getElevationData({ lat, lng }, station);

//             if (elevationData && elevationData.length > 0) {
//                 const originTotalHeight = elevationData[0].elevation + parseFloat(antennaHeight);
//                 const destinationTotalHeight =
//                     elevationData[elevationData.length - 1].elevation + parseFloat(station.height || 6);

//                 if (checkFeasibilityFromChartData(elevationData, originTotalHeight, destinationTotalHeight)) {
//                     station.elevationProfile = elevationData;
//                     station.totalDistance = calculateDistance(lat, lng, station.latitude, station.longitude);
//                     station.originTotalHeight = originTotalHeight;
//                     station.destinationTotalHeight = destinationTotalHeight;

//                     finalFeasibleStations.push(station);
//                 }
//             }
//         }

//         setFeasibleStations(finalFeasibleStations);
//         setLoading(false); // Hide loader after checking feasibility
//     };

//     // Helper function to check if client is within the sector coverage
//     function isInSector(bearing, stationAzimuth, sectorAngle) {
//         let angleDifference = Math.abs(bearing - stationAzimuth) % 360;
//         if (angleDifference > 180) {
//             angleDifference = 360 - angleDifference;
//         }
//         return angleDifference <= sectorAngle / 2;
//     }

//     const checkFeasibilityFromChartData = (elevationData, originTotalHeight, destinationTotalHeight) => {
//         const losLine = elevationData.map((_, index) => {
//             const proportion = index / (elevationData.length - 1);
//             return originTotalHeight + proportion * (destinationTotalHeight - originTotalHeight);
//         });

//         for (let i = 0; i < elevationData.length; i++) {
//             if (elevationData[i].elevation >= losLine[i]) {
//                 console.log(
//                     `LOS blocked at point ${i}: Elevation = ${elevationData[i].elevation}, LOS line = ${losLine[i]}`
//                 );
//                 return false;
//             }
//         }

//         console.log('LOS is clear based on chart data.');
//         return true;
//     };

//     const getElevationData = async (origin, destination) => {
//         const totalDistance = calculateDistance(origin.lat, origin.lng, destination.latitude, destination.longitude);
//         const numPoints = Math.ceil(totalDistance / 35);
//         const points = generateIntermediatePoints(origin, destination, numPoints);

//         try {
//             const response = await axios.post('/api/get-elevation-data', { points });
//             return response.data;
//         } catch (error) {
//             console.error('Error fetching elevation data:', error);
//             return [];
//         }
//     };

//     const generateIntermediatePoints = (origin, destination, numPoints) => {
//         const points = [];
//         const latStep = (destination.latitude - origin.lat) / numPoints;
//         const lngStep = (destination.longitude - origin.lng) / numPoints;

//         for (let i = 0; i <= numPoints; i++) {
//             const lat = origin.lat + latStep * i;
//             const lng = origin.lng + lngStep * i;
//             points.push({ lat, lng });
//         }

//         return points;
//     };

//     const calculateDistance = (lat1, lon1, lat2, lon2) => {
//         const R = 6371000;
//         const dLat = ((lat2 - lat1) * Math.PI) / 180;
//         const dLon = ((lon2 - lon1) * Math.PI) / 180;
//         const a =
//             Math.sin(dLat / 2) ** 2 +
//             Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) ** 2;
//         const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//         return R * c;
//     };

//     const calculateBearing = (lat1, lon1, lat2, lon2) => {
//         const toRadians = (degree) => (degree * Math.PI) / 180;
//         const toDegrees = (radian) => (radian * 180) / Math.PI;

//         const dLon = toRadians(lon2 - lon1);
//         const y = Math.sin(dLon) * Math.cos(toRadians(lat2));
//         const x =
//             Math.cos(toRadians(lat1)) * Math.sin(toRadians(lat2)) -
//             Math.sin(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.cos(dLon);
//         const bearing = toDegrees(Math.atan2(y, x));

//         return (bearing + 360) % 360;
//     };

//     const handleBaseStationClick = (station) => {
//         // Calculate distance and bearing
//         const distance = calculateDistance(latLng.lat, latLng.lng, station.latitude, station.longitude).toFixed(2);

//         const bearing = calculateBearing(station.latitude, station.longitude, latLng.lat, latLng.lng).toFixed(2); // Bearing from base station to client

//         setSelectedStation({
//             ...station,
//             originCoords: latLng,
//             distance,
//             bearing,
//         });
//         setOriginAddress(address); // Set the origin address
//         setShowModal(true);
//     };

//     const closeModal = () => {
//         setShowModal(false);
//     };

//     const handleClearForm = () => {
//         setAddress('');
//         setCoordinates('');
//         setLatLng(startingCoords);
//         setMaxDistance(5000);
//         setFrequency('0');
//         setAntennaHeight(6);
//         setFeasibleStations([]);
//     };

//     const markerIcon = new L.Icon({
//         iconUrl: startingOfficeLogo,
//         iconSize: [25, 38],
//         iconAnchor: [12, 38],
//     });

//     return (
//         <div style={styles.pageContainer}>
//             <Top />
//             <div id="map-container" style={styles.mapWrapper}>
//                 <MapContainer
//                     center={[latLng.lat, latLng.lng]}
//                     zoom={16}
//                     scrollWheelZoom={true}
//                     style={styles.mapContainer}
//                     attributionControl={false}
//                 >
//                     <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" />
//                     <Marker position={[latLng.lat, latLng.lng]} icon={markerIcon} />
//                     <UpdateMapCenter latLng={latLng} />
//                     <CustomAttribution />
//                     <div style={styles.searchContainer}>
//                         <div style={styles.inputGroup}>
//                             <label>Select Equipment Frequency</label>
//                             <select
//                                 value={frequency}
//                                 onChange={(e) => setFrequency(e.target.value)}
//                                 style={styles.inputBox}
//                             >
//                                 {FREQUENCY_OPTIONS.map((option) => (
//                                     <option key={option.value} value={option.value}>
//                                         {option.label}
//                                     </option>
//                                 ))}
//                             </select>
//                         </div>
//                         <div style={styles.inputGroup}>
//                             <label>Installed Antenna Height in Meters</label>
//                             <input
//                                 type="number"
//                                 value={antennaHeight}
//                                 onChange={(e) => setAntennaHeight(e.target.value)}
//                                 style={styles.inputBox}
//                             />
//                         </div>
//                         <div style={styles.inputGroup}>
//                             <label>Maximum Distance of Link in Meters</label>
//                             <input
//                                 type="number"
//                                 value={maxDistance}
//                                 onChange={(e) => setMaxDistance(e.target.value)}
//                                 style={styles.inputBox}
//                             />
//                         </div>
//                         <div style={styles.inputGroup}>
//                             <label>Search for Address</label>
//                             <input
//                                 ref={autocompleteRef}
//                                 type="text"
//                                 value={address}
//                                 onChange={(e) => setAddress(e.target.value)}
//                                 placeholder=""
//                                 style={styles.inputBox}
//                             />
//                         </div>
//                         <div style={styles.inputGroup}>
//                             <label>Enter Coordinates (latitude, longitude)</label>
//                             <input
//                                 type="text"
//                                 value={coordinates}
//                                 onChange={(e) => setCoordinates(e.target.value)}
//                                 style={styles.inputBox}
//                             />
//                         </div>
//                         <button onClick={handleSearch} style={styles.submitButton}>
//                             Search
//                         </button>
//                         <button onClick={handleClearForm} style={styles.clearButton}>
//                             Reset Search
//                         </button>
//                     </div>
//                     {feasibleStations.map((station, index) => (
//                         <Marker
//                             key={index}
//                             position={[parseFloat(station.latitude), parseFloat(station.longitude)]}
//                             icon={markerIcon}
//                             eventHandlers={{
//                                 click: () => handleBaseStationClick(station),
//                             }}
//                         />
//                     ))}
//                     {feasibleStations.map((station, index) => (
//                         <Polyline
//                             key={index}
//                             positions={[
//                                 [latLng.lat, latLng.lng],
//                                 [parseFloat(station.latitude), parseFloat(station.longitude)],
//                             ]}
//                             color="red"
//                             weight={1}
//                         />
//                     ))}
//                 </MapContainer>
//             </div>

//             {loading && (
//                 <div className="loader-overlay">
//                     <div className="loader"></div>
//                 </div>
//             )}

//             {/* No Results Modal */}
//             {noResults && (
//                 <div style={styles.noResultsOverlay} onClick={() => setNoResults(false)}>
//                     <div style={styles.noResultsModal} onClick={(e) => e.stopPropagation()}>
//                         <h3>No Results Found</h3>
//                         <p>Please try different coordinates or adjust your search parameters.</p>
//                         <button onClick={() => setNoResults(false)} style={styles.closeButton}>
//                             Close
//                         </button>
//                     </div>
//                 </div>
//             )}

//             {/* Modal styling and content
//             {showModal && selectedStation && (
//                 <div className="modal-overlay" onClick={closeModal}>
//                     <div
//                         className="modal-content"
//                         style={styles.modal}
//                         onClick={(e) => e.stopPropagation()}
//                     >
//                         <div style={styles.modalTop}>
//                             <h3 style={styles.baseStationTitle}>Base Station: {selectedStation.name}</h3>
//                             <div style={styles.stationInfo}>
//                                 <p>
//                                     Origin Coordinates: {selectedStation.originCoords.lat.toFixed(6)},{' '}
//                                     {selectedStation.originCoords.lng.toFixed(6)}
//                                 </p>
//                                 <p>Distance to Base: {selectedStation.distance} meters</p>
//                                 <p>Bearing from Base to Client: {selectedStation.bearing}°</p>
//                                 <p>Sector Azimuth: {selectedStation.azimuth}°</p>
//                                 <p>Sector Angle: {selectedStation.sector_angle}°</p>
//                                 <p>Base Station Range: {selectedStation.range} meters</p>
//                             </div>
//                         </div>
//                         <div style={styles.modalChart}>
//                             <h2>Elevation Profile</h2>
//                             {selectedStation.elevationProfile && selectedStation.totalDistance ? (
//                                 <div style={{ width: '100%', height: 'auto' }}>
//                                     <Line
//                                         ref={chartRef} // Attach the ref here
//                                         data={{
//                                             labels: selectedStation.elevationProfile.map((_, index) => {
//                                                 const proportion =
//                                                     index / (selectedStation.elevationProfile.length - 1);
//                                                 return (proportion * selectedStation.totalDistance).toFixed(2);
//                                             }),
//                                             datasets: [
//                                                 {
//                                                     label: 'Elevation Profile',
//                                                     data: selectedStation.elevationProfile.map(
//                                                         (point, index) => {
//                                                             if (index === 0) {
//                                                                 return selectedStation.originTotalHeight;
//                                                             } else if (
//                                                                 index ===
//                                                                 selectedStation.elevationProfile.length - 1
//                                                             ) {
//                                                                 return selectedStation.destinationTotalHeight;
//                                                             }
//                                                             return point.elevation;
//                                                         }
//                                                     ),
//                                                     borderColor: '#61acb4',
//                                                     borderWidth: 2,
//                                                     pointRadius: 0,
//                                                     tension: 0.4,
//                                                     fill: 'origin',
//                                                     backgroundColor: 'rgba(97, 172, 180, 0.5)',
//                                                 },
//                                                 {
//                                                     label: 'LOS Line',
//                                                     data: selectedStation.elevationProfile.map(
//                                                         (_, index) => {
//                                                             const proportion =
//                                                                 index /
//                                                                 (selectedStation.elevationProfile.length -
//                                                                     1);
//                                                             const losValue =
//                                                                 selectedStation.originTotalHeight +
//                                                                 proportion *
//                                                                     (selectedStation.destinationTotalHeight -
//                                                                         selectedStation.originTotalHeight);
//                                                             return losValue;
//                                                         }
//                                                     ),
//                                                     borderColor: 'red',
//                                                     borderWidth: 1,
//                                                     pointRadius: 0,
//                                                     tension: 0.4,
//                                                 },
//                                                 {
//                                                     label: 'First Fresnel Zone',
//                                                     data: selectedStation.elevationProfile.map(
//                                                         (_, index) => {
//                                                             const proportion =
//                                                                 index /
//                                                                 (selectedStation.elevationProfile.length -
//                                                                     1);
//                                                             const distanceFromObserver =
//                                                                 proportion * selectedStation.totalDistance;
//                                                             const distanceToBase =
//                                                                 selectedStation.totalDistance -
//                                                                 distanceFromObserver;

//                                                             const fresnelRadius = calculateFresnelZoneRadius(
//                                                                 1,
//                                                                 parseFloat(frequency.replace('Ghz', '')),
//                                                                 distanceFromObserver,
//                                                                 distanceToBase,
//                                                                 selectedStation.totalDistance
//                                                             );

//                                                             const fresnelValue =
//                                                                 selectedStation.originTotalHeight +
//                                                                 proportion *
//                                                                     (selectedStation.destinationTotalHeight -
//                                                                         selectedStation.originTotalHeight) -
//                                                                 fresnelRadius;
//                                                             return fresnelValue;
//                                                         }
//                                                     ),
//                                                     borderColor: 'rgba(50, 205, 50, 0.5)',
//                                                     borderWidth: 1,
//                                                     pointRadius: 0,
//                                                     tension: 0.4,
//                                                 },
//                                                 {
//                                                     label: 'Second Fresnel Zone',
//                                                     data: selectedStation.elevationProfile.map(
//                                                         (_, index) => {
//                                                             const proportion =
//                                                                 index /
//                                                                 (selectedStation.elevationProfile.length -
//                                                                     1);
//                                                             const distanceFromObserver =
//                                                                 proportion * selectedStation.totalDistance;
//                                                             const distanceToBase =
//                                                                 selectedStation.totalDistance -
//                                                                 distanceFromObserver;

//                                                             const fresnelRadius = calculateFresnelZoneRadius(
//                                                                 2,
//                                                                 parseFloat(frequency.replace('Ghz', '')),
//                                                                 distanceFromObserver,
//                                                                 distanceToBase,
//                                                                 selectedStation.totalDistance
//                                                             );

//                                                             const fresnelValue =
//                                                                 selectedStation.originTotalHeight +
//                                                                 proportion *
//                                                                     (selectedStation.destinationTotalHeight -
//                                                                         selectedStation.originTotalHeight) -
//                                                                 fresnelRadius;
//                                                             return fresnelValue;
//                                                         }
//                                                     ),
//                                                     borderColor: 'rgba(30, 144, 255, 0.5)',
//                                                     borderWidth: 1,
//                                                     pointRadius: 0,
//                                                     tension: 0.4,
//                                                 },
//                                             ],
//                                         }}
//                                         options={{
//                                             responsive: true, // Ensure chart resizes with the modal
//                                             maintainAspectRatio: false, // Disable aspect ratio to allow flexible height
//                                             scales: {
//                                                 x: {
//                                                     title: { display: true, text: 'Distance from Origin (m)' },
//                                                     ticks: { maxTicksLimit: 40 },
//                                                 },
//                                                 y: {
//                                                     title: { display: true, text: 'Elevation (m)' },
//                                                     min:
//                                                         Math.min(
//                                                             ...selectedStation.elevationProfile.map(
//                                                                 (point) => point.elevation
//                                                             )
//                                                         ) - 20, // Dynamically set Y-axis minimum
//                                                 },
//                                             },
//                                             layout: {
//                                                 padding: {
//                                                     top: 10,
//                                                     left: 10,
//                                                     right: 10,
//                                                     bottom: 10,
//                                                 },
//                                             },
//                                         }}
//                                         height={300} // Allow parent container to control height
//                                         width={null} // Allow parent container to control width
//                                     />
//                                 </div>
//                             ) : (
//                                 <p>No elevation profile available</p>
//                             )}
//                         </div>

//                         <div style={styles.printButtonContainer}>
//                             <button
//                                 style={styles.printButton}
//                                 onClick={() =>
//                                     handlePrintResults(selectedStation, chartRef, originAddress, latLng)
//                                 }
//                             >
//                                 Save and Print Report
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//             )} */}
//             {/* Modal styling and content */}
//                 {showModal && selectedStation && (
//                     <div className="modal-overlay" onClick={closeModal}>
//                         <div
//                             className="modal-content"
//                             style={styles.modal}
//                             onClick={(e) => e.stopPropagation()}
//                         >
//                             <div style={styles.modalTop}>
//                                 <h3 style={styles.baseStationTitle}>Base Station: {selectedStation.name}</h3>
//                                 <div style={styles.stationInfo}>
//                                     {/* First Pair */}
//                                     <div style={styles.infoRow}>
//                                         <div style={styles.infoPair}>
//                                             <p>
//                                                 <strong>Origin Coordinates:</strong>
//                                                 <br />
//                                                 {selectedStation.originCoords.lat.toFixed(6)}, {selectedStation.originCoords.lng.toFixed(6)}
//                                             </p>
//                                         </div>
//                                         <div style={styles.infoPair}>
//                                             <p>
//                                                 <strong>Distance to Base:</strong>
//                                                 <br />
//                                                 {selectedStation.distance} meters
//                                             </p>
//                                         </div>
//                                     </div>
//                                     {/* Second Pair */}
//                                     <div style={styles.infoRow}>
//                                         <div style={styles.infoPair}>
//                                             <p>
//                                                 <strong>Bearing from Base to Client:</strong>
//                                                 <br />
//                                                 {selectedStation.bearing}°
//                                             </p>
//                                         </div>
//                                         <div style={styles.infoPair}>
//                                             <p>
//                                                 <strong>Sector Azimuth:</strong>
//                                                 <br />
//                                                 {selectedStation.azimuth}°
//                                             </p>
//                                         </div>
//                                     </div>
//                                     {/* Third Pair */}
//                                     <div style={styles.infoRow}>
//                                         <div style={styles.infoPair}>
//                                             <p>
//                                                 <strong>Sector Angle:</strong>
//                                                 <br />
//                                                 {selectedStation.sector_angle}°
//                                             </p>
//                                         </div>
//                                         <div style={styles.infoPair}>
//                                             <p>
//                                                 <strong>Base Station Range:</strong>
//                                                 <br />
//                                                 {selectedStation.range} meters
//                                             </p>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div style={styles.modalChart}>
//                                 <h2>Elevation Profile</h2>
//                                 {selectedStation.elevationProfile && selectedStation.totalDistance ? (
//                                     <div style={{ width: '100%', height: 'auto' }}>
//                                         <Line
//                                             ref={chartRef} // Attach the ref here
//                                             data={{
//                                                 labels: selectedStation.elevationProfile.map((_, index) => {
//                                                     const proportion =
//                                                         index / (selectedStation.elevationProfile.length - 1);
//                                                     return (proportion * selectedStation.totalDistance).toFixed(2);
//                                                 }),
//                                                 datasets: [
//                                                     {
//                                                         label: 'Elevation Profile',
//                                                         data: selectedStation.elevationProfile.map(
//                                                             (point, index) => {
//                                                                 if (index === 0) {
//                                                                     return selectedStation.originTotalHeight;
//                                                                 } else if (
//                                                                     index ===
//                                                                     selectedStation.elevationProfile.length - 1
//                                                                 ) {
//                                                                     return selectedStation.destinationTotalHeight;
//                                                                 }
//                                                                 return point.elevation;
//                                                             }
//                                                         ),
//                                                         borderColor: '#61acb4',
//                                                         borderWidth: 2,
//                                                         pointRadius: 0,
//                                                         tension: 0.4,
//                                                         fill: 'origin',
//                                                         backgroundColor: 'rgba(97, 172, 180, 0.5)',
//                                                     },
//                                                     {
//                                                         label: 'LOS Line',
//                                                         data: selectedStation.elevationProfile.map(
//                                                             (_, index) => {
//                                                                 const proportion =
//                                                                     index /
//                                                                     (selectedStation.elevationProfile.length - 1);
//                                                                 const losValue =
//                                                                     selectedStation.originTotalHeight +
//                                                                     proportion *
//                                                                         (selectedStation.destinationTotalHeight -
//                                                                             selectedStation.originTotalHeight);
//                                                                 return losValue;
//                                                             }
//                                                         ),
//                                                         borderColor: 'red',
//                                                         borderWidth: 1,
//                                                         pointRadius: 0,
//                                                         tension: 0.4,
//                                                     },
//                                                     {
//                                                         label: 'First Fresnel Zone',
//                                                         data: selectedStation.elevationProfile.map(
//                                                             (_, index) => {
//                                                                 const proportion =
//                                                                     index /
//                                                                     (selectedStation.elevationProfile.length - 1);
//                                                                 const distanceFromObserver =
//                                                                     proportion * selectedStation.totalDistance;
//                                                                 const distanceToBase =
//                                                                     selectedStation.totalDistance -
//                                                                     distanceFromObserver;

//                                                                 const fresnelRadius = calculateFresnelZoneRadius(
//                                                                     1,
//                                                                     parseFloat(frequency.replace('Ghz', '')),
//                                                                     distanceFromObserver,
//                                                                     distanceToBase,
//                                                                     selectedStation.totalDistance
//                                                                 );

//                                                                 const fresnelValue =
//                                                                     selectedStation.originTotalHeight +
//                                                                     proportion *
//                                                                         (selectedStation.destinationTotalHeight -
//                                                                             selectedStation.originTotalHeight) -
//                                                                     fresnelRadius;
//                                                                 return fresnelValue;
//                                                             }
//                                                         ),
//                                                         borderColor: 'rgba(50, 205, 50, 0.5)',
//                                                         borderWidth: 1,
//                                                         pointRadius: 0,
//                                                         tension: 0.4,
//                                                     },
//                                                     {
//                                                         label: 'Second Fresnel Zone',
//                                                         data: selectedStation.elevationProfile.map(
//                                                             (_, index) => {
//                                                                 const proportion =
//                                                                     index /
//                                                                     (selectedStation.elevationProfile.length - 1);
//                                                                 const distanceFromObserver =
//                                                                     proportion * selectedStation.totalDistance;
//                                                                 const distanceToBase =
//                                                                     selectedStation.totalDistance -
//                                                                     distanceFromObserver;

//                                                                 const fresnelRadius = calculateFresnelZoneRadius(
//                                                                     2,
//                                                                     parseFloat(frequency.replace('Ghz', '')),
//                                                                     distanceFromObserver,
//                                                                     distanceToBase,
//                                                                     selectedStation.totalDistance
//                                                                 );

//                                                                 const fresnelValue =
//                                                                     selectedStation.originTotalHeight +
//                                                                     proportion *
//                                                                         (selectedStation.destinationTotalHeight -
//                                                                             selectedStation.originTotalHeight) -
//                                                                     fresnelRadius;
//                                                                 return fresnelValue;
//                                                             }
//                                                         ),
//                                                         borderColor: 'rgba(30, 144, 255, 0.5)',
//                                                         borderWidth: 1,
//                                                         pointRadius: 0,
//                                                         tension: 0.4,
//                                                     },
//                                                 ],
//                                             }}
//                                             options={{
//                                                 responsive: true, // Ensure chart resizes with the modal
//                                                 maintainAspectRatio: false, // Disable aspect ratio to allow flexible height
//                                                 scales: {
//                                                     x: {
//                                                         title: { display: true, text: 'Distance from Origin (m)' },
//                                                         ticks: { maxTicksLimit: 40 },
//                                                     },
//                                                     y: {
//                                                         title: { display: true, text: 'Elevation (m)' },
//                                                         min:
//                                                             Math.min(
//                                                                 ...selectedStation.elevationProfile.map(
//                                                                     (point) => point.elevation
//                                                                 )
//                                                             ) - 20, // Dynamically set Y-axis minimum
//                                                     },
//                                                 },
//                                                 layout: {
//                                                     padding: {
//                                                         top: 10,
//                                                         left: 10,
//                                                         right: 10,
//                                                         bottom: 10,
//                                                     },
//                                                 },
//                                             }}
//                                             height={300} // Allow parent container to control height
//                                             width={null} // Allow parent container to control width
//                                         />
//                                     </div>
//                                 ) : (
//                                     <p>No elevation profile available</p>
//                                 )}
//                             </div>

//                             <div style={styles.printButtonContainer}>
//                                 <button
//                                     style={styles.printButton}
//                                     onClick={() =>
//                                         handlePrintResults(selectedStation, chartRef, originAddress, latLng)
//                                     }
//                                 >
//                                     Save and Print Report
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 )}

//         </div>
//     );
// }

// // Styles for the Wireless component
// const styles = {
//     pageContainer: {
//         height: '100vh',
//         display: 'flex',
//         flexDirection: 'column',
//         overflow: 'hidden',
//     },
//     mapWrapper: {
//         width: '100%',
//         height: '100%',
//         borderRadius: 'inherit',
//         overflow: 'hidden',
//     },
//     mapContainer: {
//         width: '100%',
//         height: '100%',
//     },
//     searchContainer: {
//         position: 'absolute',
//         top: '15px',
//         left: '55px',
//         backgroundColor: 'white',
//         padding: '12px',
//         borderRadius: '12px',
//         boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
//         zIndex: 1000,
//         width: '25%',
//     },
//     inputBox: {
//         width: '90%',
//         padding: '10px',
//         borderRadius: '5px',
//         border: '1px solid slategray',
//         marginBottom: '10px',
//     },
//     inputGroup: {
//         marginBottom: '10px',
//     },
//     submitButton: {
//         padding: '10px',
//         borderRadius: '6px',
//         backgroundColor: '#61acb4',
//         color: 'white',
//         cursor: 'pointer',
//         border: 'none',
//         fontSize: '1rem', // Use rem units for scalability
//     },
//     clearButton: {
//         padding: '10px',
//         borderRadius: '6px',
//         backgroundColor: 'red',
//         color: 'white',
//         cursor: 'pointer',
//         border: 'none',
//         marginLeft: '10px',
//         fontSize: '1rem', // Use rem units for scalability
//     },
//     modal: {
//         width: '80%', // Use percentage width for responsiveness
//         maxWidth: '1000px', // Set a maximum width
//         height: 'auto',
//         maxHeight: '90vh', // Set a maximum height based on viewport height
//         position: 'fixed',
//         top: '5%',
//         left: '50%',
//         transform: 'translateX(-50%)',
//         backgroundColor: 'white',
//         padding: '20px',
//         boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
//         borderRadius: '12px',
//         zIndex: 2000,
//         textAlign: 'center',
//         overflowY: 'auto', // Enable vertical scrolling if content overflows
//     },
//     modalTop: {
//         width: '100%',
//         textAlign: 'center',
//         marginBottom: '10px',
//     },
//     baseStationTitle: {
//         fontSize: '1.5rem', // Use rem for scalability
//         marginBottom: '5px',
//     },
//     stationInfo: {
//         fontSize: '1rem',
//         margin: '5px 0',
//         display: 'flex',
//         justifyContent: 'space-around',
//         flexWrap: 'wrap', // Allow wrapping on smaller screens
//     },
//     modalChart: {
//         textAlign: 'center',
//         marginTop: '10px',
//         width: '100%', // Ensure chart takes the full width of the modal
//         height: '50vh',
//     },
//     printButtonContainer: {
//         marginTop: '20px',
//         textAlign: 'center',
//     },
//     printButton: {
//         padding: '10px',
//         borderRadius: '6px',
//         backgroundColor: '#61acb4',
//         color: 'white',
//         cursor: 'pointer',
//         border: 'none',
//         fontSize: '1rem', // Responsive font size
//     },
//     noResultsModal: {
//         width: '50%', // Use a smaller width for the no-results modal
//         height: 'auto',
//         position: 'fixed',
//         top: '30%',
//         left: '50%',
//         transform: 'translateX(-50%)',
//         backgroundColor: 'white',
//         padding: '20px',
//         boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)', // Slightly deeper shadow
//         borderRadius: '16px', // Larger rounded corners
//         zIndex: 2000,
//         textAlign: 'center', // Center the content
//     },
//     noResultsOverlay: {
//         position: 'fixed',
//         top: 0,
//         left: 0,
//         width: '100%',
//         height: '100%',
//         backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
//         zIndex: 1999,
//     },
//     closeButton: {
//         padding: '10px 20px',
//         borderRadius: '8px',
//         backgroundColor: '#ff4d4d', // Red button to indicate no results
//         color: 'white',
//         border: 'none',
//         cursor: 'pointer',
//         marginTop: '20px',
//     },
//     stationInfo: {
//         margin: '5px 0',
//     },
//     infoRow: {
//         display: 'flex',
//         // justifyContent: 'space-around',
//         marginBottom: '5px',
//     },
//     infoPair: {
//         flex: '1',
//         textAlign: 'center',
//     },
// };

// export default Wireless;

import React, { useState, useEffect, useRef } from 'react';
import Top from './Top';
import { MapContainer, TileLayer, Marker, Polyline, Popup, useMap, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';
import './Modal.css'; // Include your modal styles
import frogfootConfig from './frogfootConfig'; // Google API config for autocomplete
import { Line } from 'react-chartjs-2'; // Chart.js Line chart for elevation profile
import 'chart.js/auto'; // Auto registration of charts
import './Loader.css';

// List of loading quotes
const loadingQuotes = [
    "Loading your results...",
    "Calculating feasibility...",
    "Analyzing data...",
    "Please wait a moment...",
    "I can see my house from up here...",
    "Processing your request...",
    "Loading your future network... one pixel at a time",
    "Theres no place like 127.0.0.1",
];

// Function to log search activity
const logSearch = async (searchType, coordinates) => {
    try {
        const response = await fetch('https://dkwireless.smartmap.world/api/log-search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                searchType: searchType,
                coordinates: JSON.stringify(coordinates),
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to log search');
        }

        const data = await response.json();
        console.log('Search logged:', data);
    } catch (error) {
        console.error('Error logging search:', error);
    }
};

// Constants for initial map setup and available frequency options
const startingCoords = { lat: -32.90243028852015, lng: 17.99862167001142 }; // Initial map center coordinates
const startingOfficeLogo = './markersm.png'; // Marker icon for the user's location
const FREQUENCY_OPTIONS = [
    { value: '0', label: 'No Radio Calculation' },
    { value: '2Ghz', label: '2 GHz' },
    { value: '5Ghz', label: '5 GHz' },
    { value: '11Ghz', label: '11 GHz' },
    { value: '17Ghz', label: '17 GHz' },
    { value: '24Ghz', label: '24 GHz' },
    { value: '60Ghz', label: '60 GHz' },
    { value: '80Ghz', label: '80 GHz' },
];

// Calculate Fresnel Zone Radius
function calculateFresnelZoneRadius(n, frequency, d1, d2, totalDistance) {
    const speedOfLight = 299792458; // Speed of light in meters/second
    const wavelength = speedOfLight / (frequency * 1e9); // Convert GHz to Hz
    const fresnelRadius = Math.sqrt((n * wavelength * d1 * d2) / (d1 + d2));
    console.log(`Fresnel Zone Calculation: n=${n}, frequency=${frequency}GHz, d1=${d1}, d2=${d2}, totalDistance=${totalDistance}, fresnelRadius=${fresnelRadius}`);
    return fresnelRadius;
}

// Update map center when latLng changes
function UpdateMapCenter({ latLng }) {
    const map = useMap();
    useEffect(() => {
        map.setView([latLng.lat, latLng.lng], 13); // Zoom level set to 13
    }, [latLng, map]);
    return null;
}

// Logo attribution at the bottom-right corner of the map
function CustomAttribution() {
    const map = useMap();

    useEffect(() => {
        const logoControl = L.control({ position: 'bottomright' });
        logoControl.onAdd = function () {
            const div = L.DomUtil.create('div', 'custom-attribution');
            div.innerHTML = `<img src="./smartmaplogotext.png" alt="Logo" style="height: 40px;"/>`;
            return div;
        };
        logoControl.addTo(map);
        return () => {
            map.removeControl(logoControl);
        };
    }, [map]);

    return null;
}

// Component to handle right-click and display popup for feasibility check
function RightClickFeasibility({ onRightClickConfirm }) {
    const [popupPosition, setPopupPosition] = useState(null); // State for popup position
    const [clickedLatLng, setClickedLatLng] = useState(null); // State to store clicked coordinates

    useMapEvents({
        contextmenu(event) {
            const { latlng } = event;
            setPopupPosition(latlng); // Set popup position
            setClickedLatLng(latlng); // Store clicked coordinates
        }
    });

    return popupPosition ? (
        <Popup
            position={popupPosition}
            onClose={() => setPopupPosition(null)} // Close popup if dismissed
        >
            <div style={styles.popupContainer}>
                <p style={styles.popupText}>Would you like to get feasibility at this location?</p>
                <button
                    style={styles.submitButton} // Style the button the same as the search button
                    onClick={() => {
                        onRightClickConfirm(clickedLatLng); // Call the function with clicked coordinates
                        setPopupPosition(null); // Close popup after confirmation
                    }}
                >
                    Get Feasibility
                </button>
            </div>
        </Popup>
    ) : null;
}

function Wireless() {
    const [loading, setLoading] = useState(false); // Loading state for preloader
    const [address, setAddress] = useState(''); // Input address
    const [coordinates, setCoordinates] = useState(''); // Coordinates input
    const [latLng, setLatLng] = useState(startingCoords); // Current latLng of the map center
    const [maxDistance, setMaxDistance] = useState(5000); // Max distance for station filtering
    const [frequency, setFrequency] = useState('0'); // Selected frequency option
    const [antennaHeight, setAntennaHeight] = useState(6); // Default antenna height in meters
    const [baseStations, setBaseStations] = useState([]); // List of all base stations
    const [feasibleStations, setFeasibleStations] = useState([]); // Filtered feasible stations
    const [showModal, setShowModal] = useState(false); // Modal visibility state
    const [selectedStation, setSelectedStation] = useState(null); // Selected station for modal display
    const [addressSelected, setAddressSelected] = useState(false); // Tracks if an address was selected via autocomplete
    const [noResults, setNoResults] = useState(false); // Track no results found
    const chartRef = useRef(null); // Reference to the elevation chart
    const [originAddress, setOriginAddress] = useState(''); // Track the origin address
    const autocompleteRef = useRef(null); // Reference for Google Maps autocomplete input
    const [quote, setQuote] = useState(''); // State for random quote
    const quoteIntervalRef = useRef(null); // Ref to store interval ID for quotes
    const mapRef = useRef(null); // Reference to the map instance

    useEffect(() => {
        loadGoogleMapsScript(); // Load Google Maps script for address autocomplete
        fetchBaseStations(); // Fetch all available base stations
    }, []);

    const loadGoogleMapsScript = () => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${frogfootConfig.googleApiKey}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = initAutocomplete;
        document.head.appendChild(script);
    };

    const initAutocomplete = () => {
        const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current);
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                setCoordinates(`${lat},${lng}`);
                setLatLng({ lat, lng }); // Update map center
                setAddress(place.formatted_address);
                setAddressSelected(true); // Trigger feasibility check

                // Log the search after the place is selected
                logSearch('Wireless', `${lat},${lng}`);
            } else {
                console.error("No geometry found for the selected place.");
            }
        });
    };

    const fetchBaseStations = async () => {
        setLoading(true); // Show loader during fetch
        try {
            const response = await axios.get('/api/get-base-points');
            setBaseStations(response.data);
        } catch (error) {
            console.error('Error fetching base stations:', error);
        } finally {
            setLoading(false); // Hide loader after fetch
        }
    };

    useEffect(() => {
        if (addressSelected) {
            const [lat, lng] = coordinates.split(',').map((coord) => parseFloat(coord.trim()));
            checkFeasibilityAndElevation(lat, lng);
            setAddressSelected(false); // Reset state after check
        }
    }, [addressSelected, coordinates]);

    const handleSearch = () => {
        const [lat, lng] = coordinates.split(',').map((coord) => parseFloat(coord.trim()));
        if (!isNaN(lat) && !isNaN(lng)) {
            setLatLng({ lat, lng });
            checkFeasibilityAndElevation(lat, lng);

            // Log the search after it's performed
            logSearch('Wireless', coordinates);
        } else {
            alert('Please enter valid coordinates.');
        }
    };

    // Function to get a random quote
    const getRandomQuote = () => {
        const randomIndex = Math.floor(Math.random() * loadingQuotes.length);
        return loadingQuotes[randomIndex];
    };

    // Functions to start and stop quote rotation
    const startQuoteRotation = () => {
        setQuote(getRandomQuote()); // Show a quote immediately
        quoteIntervalRef.current = setInterval(() => {
            setQuote(getRandomQuote()); // Update quote every 5 seconds
        }, 5000);
    };

    const stopQuoteRotation = () => {
        clearInterval(quoteIntervalRef.current); // Stop rotating quotes when loading ends
    };

    const checkFeasibilityAndElevation = async (lat, lng) => {
        startQuoteRotation(); // Start rotating quotes
        setLoading(true); // Show loader while checking feasibility
        setNoResults(false); // Reset no results state
        let finalFeasibleStations = [];

        const feasible = baseStations.filter((station) => {
            const stationLat = parseFloat(station.latitude);
            const stationLng = parseFloat(station.longitude);
            const distance = calculateDistance(lat, lng, stationLat, stationLng);

            // Use station's range if defined; else default to user's maxDistance
            const stationRange = station.range ? parseFloat(station.range) : maxDistance;
            const effectiveRange = Math.min(stationRange, maxDistance);

            // Check distance
            if (distance > effectiveRange) {
                return false;
            }

            // Calculate bearing from base station to client
            const bearing = calculateBearing(stationLat, stationLng, lat, lng);

            // Get station's azimuth and sector_angle
            const stationAzimuth = station.azimuth ? parseFloat(station.azimuth) : 0; // Default to 0
            const sectorAngle = station.sector_angle ? parseFloat(station.sector_angle) : 360; // Default to 360

            // Check if the client is within the sector
            const inSector = isInSector(bearing, stationAzimuth, sectorAngle);

            // Debugging logs
            console.log(`Station: ${station.name}`);
            console.log(`Distance: ${distance}`);
            console.log(`Bearing: ${bearing}`);
            console.log(`Azimuth: ${stationAzimuth}`);
            console.log(`Sector Angle: ${sectorAngle}`);
            console.log(`In Sector: ${inSector}`);

            return inSector;
        });

        if (feasible.length === 0) {
            setNoResults(true); // No feasible stations found
            setLoading(false); // Hide loader after checking feasibility
            stopQuoteRotation(); // Stop rotating quotes
            return;
        }

        for (const station of feasible) {
            const elevationData = await getElevationData({ lat, lng }, station);

            if (elevationData && elevationData.length > 0) {
                const originTotalHeight = elevationData[0].elevation + parseFloat(antennaHeight);
                const destinationTotalHeight =
                    elevationData[elevationData.length - 1].elevation + parseFloat(station.height || 6);

                if (checkFeasibilityFromChartData(elevationData, originTotalHeight, destinationTotalHeight)) {
                    station.elevationProfile = elevationData;
                    station.totalDistance = calculateDistance(lat, lng, station.latitude, station.longitude);
                    station.originTotalHeight = originTotalHeight;
                    station.destinationTotalHeight = destinationTotalHeight;

                    finalFeasibleStations.push(station);
                }
            }
        }

        setFeasibleStations(finalFeasibleStations);
        setLoading(false); // Hide loader after checking feasibility
        stopQuoteRotation(); // Stop rotating quotes
    };

    // Helper function to check if client is within the sector coverage
    function isInSector(bearing, stationAzimuth, sectorAngle) {
        let angleDifference = Math.abs(bearing - stationAzimuth) % 360;
        if (angleDifference > 180) {
            angleDifference = 360 - angleDifference;
        }
        return angleDifference <= sectorAngle / 2;
    }

    const checkFeasibilityFromChartData = (elevationData, originTotalHeight, destinationTotalHeight) => {
        const losLine = elevationData.map((_, index) => {
            const proportion = index / (elevationData.length - 1);
            return originTotalHeight + proportion * (destinationTotalHeight - originTotalHeight);
        });

        for (let i = 0; i < elevationData.length; i++) {
            if (elevationData[i].elevation >= losLine[i]) {
                console.log(
                    `LOS blocked at point ${i}: Elevation = ${elevationData[i].elevation}, LOS line = ${losLine[i]}`
                );
                return false;
            }
        }

        console.log('LOS is clear based on chart data.');
        return true;
    };

    const getElevationData = async (origin, destination) => {
        const totalDistance = calculateDistance(origin.lat, origin.lng, destination.latitude, destination.longitude);
        const numPoints = Math.ceil(totalDistance / 35);
        const points = generateIntermediatePoints(origin, destination, numPoints);

        try {
            const response = await axios.post('/api/get-elevation-data', { points });
            return response.data;
        } catch (error) {
            console.error('Error fetching elevation data:', error);
            return [];
        }
    };

    const generateIntermediatePoints = (origin, destination, numPoints) => {
        const points = [];
        const latStep = (destination.latitude - origin.lat) / numPoints;
        const lngStep = (destination.longitude - origin.lng) / numPoints;

        for (let i = 0; i <= numPoints; i++) {
            const lat = origin.lat + latStep * i;
            const lng = origin.lng + lngStep * i;
            points.push({ lat, lng });
        }

        return points;
    };

    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371000;
        const dLat = ((lat2 - lat1) * Math.PI) / 180;
        const dLon = ((lon2 - lon1) * Math.PI) / 180;
        const a =
            Math.sin(dLat / 2) ** 2 +
            Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) ** 2;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    };

    const calculateBearing = (lat1, lon1, lat2, lon2) => {
        const toRadians = (degree) => (degree * Math.PI) / 180;
        const toDegrees = (radian) => (radian * 180) / Math.PI;

        const dLon = toRadians(lon2 - lon1);
        const y = Math.sin(dLon) * Math.cos(toRadians(lat2));
        const x =
            Math.cos(toRadians(lat1)) * Math.sin(toRadians(lat2)) -
            Math.sin(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.cos(dLon);
        const bearing = toDegrees(Math.atan2(y, x));

        return (bearing + 360) % 360;
    };

    const handleBaseStationClick = (station) => {
        // Calculate distance and bearing
        const distance = calculateDistance(latLng.lat, latLng.lng, station.latitude, station.longitude).toFixed(2);

        const bearing = calculateBearing(station.latitude, station.longitude, latLng.lat, latLng.lng).toFixed(2); // Bearing from base station to client

        setSelectedStation({
            ...station,
            originCoords: latLng,
            distance,
            bearing,
        });
        setOriginAddress(address); // Set the origin address
        setShowModal(true);
    };

    const handleRightClickConfirm = (latLng) => {
        setLatLng(latLng); // Update state with the right-clicked coordinates
        setCoordinates(`${latLng.lat},${latLng.lng}`); // Update the coordinates in the input field
        checkFeasibilityAndElevation(latLng.lat, latLng.lng);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleClearForm = () => {
        setAddress('');
        setCoordinates('');
        setLatLng(startingCoords);
        setMaxDistance(5000);
        setFrequency('0');
        setAntennaHeight(6);
        setFeasibleStations([]);
    };

    const handlePrintResults = (station, chartRef, originAddress, originCoords) => {
        const chartImage = chartRef.current.toBase64Image();

        axios
            .post(
                '/api/generate-pdf',
                {
                    station,
                    chartImage,
                    originAddress,
                    originCoords,
                },
                {
                    responseType: 'blob',
                }
            )
            .then((response) => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${station.name}_Report.pdf`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                console.error('Error generating PDF:', error);
            });
    };

    const markerIcon = new L.Icon({
        iconUrl: startingOfficeLogo,
        iconSize: [25, 38],
        iconAnchor: [12, 38],
    });

    return (
        <div style={styles.pageContainer}>
            <Top />
            <div id="map-container" style={styles.mapWrapper}>
                <MapContainer
                    center={[latLng.lat, latLng.lng]}
                    zoom={16}
                    scrollWheelZoom={true}
                    style={styles.mapContainer}
                    attributionControl={false}
                    whenCreated={(mapInstance) => (mapRef.current = mapInstance)}
                >
                    <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" />
                    <Marker position={[latLng.lat, latLng.lng]} icon={markerIcon} />
                    <UpdateMapCenter latLng={latLng} />
                    <CustomAttribution />
                    <RightClickFeasibility onRightClickConfirm={handleRightClickConfirm} />
                    <div style={styles.searchContainer}>
                        <div style={styles.inputGroup}>
                            <label>Select Equipment Frequency</label>
                            <select
                                value={frequency}
                                onChange={(e) => setFrequency(e.target.value)}
                                style={styles.inputBox}
                            >
                                {FREQUENCY_OPTIONS.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div style={styles.inputGroup}>
                            <label>Installed Antenna Height in Meters</label>
                            <input
                                type="number"
                                value={antennaHeight}
                                onChange={(e) => setAntennaHeight(e.target.value)}
                                style={styles.inputBox}
                            />
                        </div>
                        <div style={styles.inputGroup}>
                            <label>Maximum Distance of Link in Meters</label>
                            <input
                                type="number"
                                value={maxDistance}
                                onChange={(e) => setMaxDistance(e.target.value)}
                                style={styles.inputBox}
                            />
                        </div>
                        <div style={styles.inputGroup}>
                            <label>Search for Address</label>
                            <input
                                ref={autocompleteRef}
                                type="text"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                placeholder=""
                                style={styles.inputBox}
                            />
                        </div>
                        <div style={styles.inputGroup}>
                            <label>Enter Coordinates (latitude, longitude)</label>
                            <input
                                type="text"
                                value={coordinates}
                                onChange={(e) => setCoordinates(e.target.value)}
                                style={styles.inputBox}
                            />
                        </div>
                        <p style={styles.rightClickInstructions}>
                            Enter an address, coordinates, or right-click on the map to search feasibility.
                        </p>
                        <button onClick={handleSearch} style={styles.submitButton}>
                            Search
                        </button>
                        <button onClick={handleClearForm} style={styles.clearButton}>
                            Reset Search
                        </button>
                    </div>
                    {feasibleStations.map((station, index) => (
                        <Marker
                            key={index}
                            position={[parseFloat(station.latitude), parseFloat(station.longitude)]}
                            icon={markerIcon}
                            eventHandlers={{
                                click: () => handleBaseStationClick(station),
                            }}
                        />
                    ))}
                    {feasibleStations.map((station, index) => (
                        <Polyline
                            key={index}
                            positions={[
                                [latLng.lat, latLng.lng],
                                [parseFloat(station.latitude), parseFloat(station.longitude)],
                            ]}
                            color="red"
                            weight={1}
                        />
                    ))}
                </MapContainer>
            </div>

            {loading && (
                <div className="loader-overlay" style={styles.loaderContainer}>
                    <div className="loader"></div>
                    <p style={styles.loadingQuote}>{quote}</p>
                </div>
            )}

            {/* No Results Modal */}
            {noResults && (
                <div style={styles.noResultsOverlay} onClick={() => setNoResults(false)}>
                    <div style={styles.noResultsModal} onClick={(e) => e.stopPropagation()}>
                        <h3>No Results Found</h3>
                        <p>Please try different coordinates or adjust your search parameters.</p>
                        <button onClick={() => setNoResults(false)} style={styles.closeButton}>
                            Close
                        </button>
                    </div>
                </div>
            )}

            {/* Modal styling and content */}
            {showModal && selectedStation && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div
                        className="modal-content"
                        style={styles.modal}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div style={styles.modalTop}>
                            <h3 style={styles.baseStationTitle}>Base Station: {selectedStation.name}</h3>
                            <div style={styles.stationInfo}>
                                {/* First Pair */}
                                <div style={styles.infoRow}>
                                    <div style={styles.infoPair}>
                                        <p>
                                            <strong>Origin Coordinates:</strong>
                                            <br />
                                            {selectedStation.originCoords.lat.toFixed(6)}, {selectedStation.originCoords.lng.toFixed(6)}
                                        </p>
                                    </div>
                                    <div style={styles.infoPair}>
                                        <p>
                                            <strong>Distance to Base:</strong>
                                            <br />
                                            {selectedStation.distance} meters
                                        </p>
                                    </div>
                                </div>
                                {/* Second Pair */}
                                <div style={styles.infoRow}>
                                    <div style={styles.infoPair}>
                                        <p>
                                            <strong>Bearing from Base to Client:</strong>
                                            <br />
                                            {selectedStation.bearing}°
                                        </p>
                                    </div>
                                    <div style={styles.infoPair}>
                                        <p>
                                            <strong>Sector Azimuth:</strong>
                                            <br />
                                            {selectedStation.azimuth}°
                                        </p>
                                    </div>
                                </div>
                                {/* Third Pair */}
                                <div style={styles.infoRow}>
                                    <div style={styles.infoPair}>
                                        <p>
                                            <strong>Sector Angle:</strong>
                                            <br />
                                            {selectedStation.sector_angle}°
                                        </p>
                                    </div>
                                    <div style={styles.infoPair}>
                                        <p>
                                            <strong>Base Station Range:</strong>
                                            <br />
                                            {selectedStation.range} meters
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={styles.modalChart}>
                            <h2>Elevation Profile</h2>
                            {selectedStation.elevationProfile && selectedStation.totalDistance ? (
                                <div style={{ width: '100%', height: 'auto' }}>
                                    <Line
                                        ref={chartRef} // Attach the ref here
                                        data={{
                                            labels: selectedStation.elevationProfile.map((_, index) => {
                                                const proportion =
                                                    index / (selectedStation.elevationProfile.length - 1);
                                                return (proportion * selectedStation.totalDistance).toFixed(2);
                                            }),
                                            datasets: [
                                                {
                                                    label: 'Elevation Profile',
                                                    data: selectedStation.elevationProfile.map(
                                                        (point, index) => {
                                                            if (index === 0) {
                                                                return selectedStation.originTotalHeight;
                                                            } else if (
                                                                index ===
                                                                selectedStation.elevationProfile.length - 1
                                                            ) {
                                                                return selectedStation.destinationTotalHeight;
                                                            }
                                                            return point.elevation;
                                                        }
                                                    ),
                                                    borderColor: '#61acb4',
                                                    borderWidth: 2,
                                                    pointRadius: 0,
                                                    tension: 0.4,
                                                    fill: 'origin',
                                                    backgroundColor: 'rgba(97, 172, 180, 0.5)',
                                                },
                                                {
                                                    label: 'LOS Line',
                                                    data: selectedStation.elevationProfile.map(
                                                        (_, index) => {
                                                            const proportion =
                                                                index /
                                                                (selectedStation.elevationProfile.length - 1);
                                                            const losValue =
                                                                selectedStation.originTotalHeight +
                                                                proportion *
                                                                    (selectedStation.destinationTotalHeight -
                                                                        selectedStation.originTotalHeight);
                                                            return losValue;
                                                        }
                                                    ),
                                                    borderColor: 'red',
                                                    borderWidth: 1,
                                                    pointRadius: 0,
                                                    tension: 0.4,
                                                },
                                                {
                                                    label: 'First Fresnel Zone',
                                                    data: selectedStation.elevationProfile.map(
                                                        (_, index) => {
                                                            const proportion =
                                                                index /
                                                                (selectedStation.elevationProfile.length - 1);
                                                            const distanceFromObserver =
                                                                proportion * selectedStation.totalDistance;
                                                            const distanceToBase =
                                                                selectedStation.totalDistance -
                                                                distanceFromObserver;

                                                            const fresnelRadius = calculateFresnelZoneRadius(
                                                                1,
                                                                parseFloat(frequency.replace('Ghz', '')),
                                                                distanceFromObserver,
                                                                distanceToBase,
                                                                selectedStation.totalDistance
                                                            );

                                                            const fresnelValue =
                                                                selectedStation.originTotalHeight +
                                                                proportion *
                                                                    (selectedStation.destinationTotalHeight -
                                                                        selectedStation.originTotalHeight) -
                                                                fresnelRadius;
                                                            return fresnelValue;
                                                        }
                                                    ),
                                                    borderColor: 'rgba(50, 205, 50, 0.5)',
                                                    borderWidth: 1,
                                                    pointRadius: 0,
                                                    tension: 0.4,
                                                },
                                                {
                                                    label: 'Second Fresnel Zone',
                                                    data: selectedStation.elevationProfile.map(
                                                        (_, index) => {
                                                            const proportion =
                                                                index /
                                                                (selectedStation.elevationProfile.length - 1);
                                                            const distanceFromObserver =
                                                                proportion * selectedStation.totalDistance;
                                                            const distanceToBase =
                                                                selectedStation.totalDistance -
                                                                distanceFromObserver;

                                                            const fresnelRadius = calculateFresnelZoneRadius(
                                                                2,
                                                                parseFloat(frequency.replace('Ghz', '')),
                                                                distanceFromObserver,
                                                                distanceToBase,
                                                                selectedStation.totalDistance
                                                            );

                                                            const fresnelValue =
                                                                selectedStation.originTotalHeight +
                                                                proportion *
                                                                    (selectedStation.destinationTotalHeight -
                                                                        selectedStation.originTotalHeight) -
                                                                fresnelRadius;
                                                            return fresnelValue;
                                                        }
                                                    ),
                                                    borderColor: 'rgba(30, 144, 255, 0.5)',
                                                    borderWidth: 1,
                                                    pointRadius: 0,
                                                    tension: 0.4,
                                                },
                                            ],
                                        }}
                                        options={{
                                            responsive: true, // Ensure chart resizes with the modal
                                            maintainAspectRatio: false, // Disable aspect ratio to allow flexible height
                                            scales: {
                                                x: {
                                                    title: { display: true, text: 'Distance from Origin (m)' },
                                                    ticks: { maxTicksLimit: 40 },
                                                },
                                                y: {
                                                    title: { display: true, text: 'Elevation (m)' },
                                                    min:
                                                        Math.min(
                                                            ...selectedStation.elevationProfile.map(
                                                                (point) => point.elevation
                                                            )
                                                        ) - 20, // Dynamically set Y-axis minimum
                                                },
                                            },
                                            layout: {
                                                padding: {
                                                    top: 10,
                                                    left: 10,
                                                    right: 10,
                                                    bottom: 10,
                                                },
                                            },
                                        }}
                                        height={300} // Allow parent container to control height
                                        width={null} // Allow parent container to control width
                                    />
                                </div>
                            ) : (
                                <p>No elevation profile available</p>
                            )}
                        </div>

                        <div style={styles.printButtonContainer}>
                            <button
                                style={styles.printButton}
                                onClick={() =>
                                    handlePrintResults(selectedStation, chartRef, originAddress, latLng)
                                }
                            >
                                Save and Print Report
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

// Styles for the Wireless component
const styles = {
    pageContainer: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    mapWrapper: {
        width: '100%',
        height: '100%',
        borderRadius: 'inherit',
        overflow: 'hidden',
    },
    mapContainer: {
        width: '100%',
        height: '100%',
    },
    searchContainer: {
        position: 'absolute',
        top: '15px',
        left: '55px',
        backgroundColor: 'white',
        padding: '12px',
        borderRadius: '12px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        width: '25%',
    },
    inputBox: {
        width: '90%',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid slategray',
        marginBottom: '10px',
    },
    inputGroup: {
        marginBottom: '10px',
    },
    submitButton: {
        padding: '10px',
        borderRadius: '6px',
        backgroundColor: '#61acb4',
        color: 'white',
        cursor: 'pointer',
        border: 'none',
        fontSize: '1rem', // Use rem units for scalability
    },
    clearButton: {
        padding: '10px',
        borderRadius: '6px',
        backgroundColor: 'red',
        color: 'white',
        cursor: 'pointer',
        border: 'none',
        marginLeft: '10px',
        fontSize: '1rem', // Use rem units for scalability
    },
    rightClickInstructions: {
        fontSize: '0.8em',
        color: '#6c757d',
        marginTop: '5px',
    },
    modal: {
        width: '80%', // Use percentage width for responsiveness
        maxWidth: '1000px', // Set a maximum width
        height: 'auto',
        maxHeight: '90vh', // Set a maximum height based on viewport height
        position: 'fixed',
        top: '5%',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'white',
        padding: '20px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '12px',
        zIndex: 2000,
        textAlign: 'center',
        overflowY: 'auto', // Enable vertical scrolling if content overflows
    },
    modalTop: {
        width: '100%',
        textAlign: 'center',
        marginBottom: '20px',
    },
    baseStationTitle: {
        fontSize: '1.5rem', // Use rem for scalability
        marginBottom: '10px',
    },
    stationInfo: {
        margin: '10px 0',
    },
    infoRow: {
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '15px',
        flexWrap: 'wrap', // Allow wrapping on smaller screens
    },
    infoPair: {
        flex: '1',
        textAlign: 'center',
        minWidth: '200px', // Ensure a minimum width for each pair
    },
    modalChart: {
        textAlign: 'center',
        marginTop: '20px',
        width: '100%', // Ensure chart takes the full width of the modal
        height: '50vh',
    },
    printButtonContainer: {
        marginTop: '20px',
        textAlign: 'center',
    },
    printButton: {
        padding: '10px',
        borderRadius: '6px',
        backgroundColor: '#61acb4',
        color: 'white',
        cursor: 'pointer',
        border: 'none',
        fontSize: '1rem', // Responsive font size
    },
    noResultsModal: {
        width: '50%', // Use a smaller width for the no-results modal
        height: 'auto',
        position: 'fixed',
        top: '30%',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'white',
        padding: '20px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)', // Slightly deeper shadow
        borderRadius: '16px', // Larger rounded corners
        zIndex: 2000,
        textAlign: 'center', // Center the content
    },
    noResultsOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
        zIndex: 1999,
    },
    closeButton: {
        padding: '10px 20px',
        borderRadius: '8px',
        backgroundColor: '#ff4d4d', // Red button to indicate no results
        color: 'white',
        border: 'none',
        cursor: 'pointer',
        marginTop: '20px',
    },
    popupContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    popupText: {
        marginBottom: '10px',
    },
    loaderContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', // Ensure the loader is centered
    },
    loadingQuote: {
        marginTop: '20px',
        fontSize: '1em',
        textAlign: 'center',
        color: '#333', // Quote text color
    },
};

export default Wireless;
