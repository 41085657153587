import React, { useState, useEffect, useRef } from 'react';
import Top from './Top';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import providersSettings, { loadingQuotes } from './ProvidersSettings'; // Import provider settings and loading quotes
import axios from 'axios';
import './Modal.css';
import { FaCheckCircle, FaExclamationCircle, FaCog, FaRegThumbsDown, FaRegThumbsUp } from 'react-icons/fa'; // Import icons including the gear icon for settings
import './Loader.css'; // Preloader styles
import { GoXCircle } from "react-icons/go";
import { LuCheckCircle } from "react-icons/lu"

// Constants for map setup
const startingOfficeCoords = '-32.902439295780816, 17.99865385690603';
const startingOfficeLogo = './markersm.png'; // Path to the company logo used for the marker

// Component to handle map centering based on updated latitude and longitude values
function UpdateMapCenter({ latLng }) {
    const map = useMap();
    useEffect(() => {
        map.setView([latLng.lat, latLng.lng], 11); // Updated zoom level to 11
    }, [latLng, map]);
    return null;
}

// Component to display the logo at the bottom-right corner of the map
function CustomAttribution() {
    const map = useMap();

    useEffect(() => {
        const logoControl = L.control({ position: 'bottomright' });
        logoControl.onAdd = function () {
            const div = L.DomUtil.create('div', 'custom-attribution');
            div.innerHTML = `<img src="./smartmaplogotext.png" alt="Logo" style="height: 40px;"/>`;
            return div;
        };
        logoControl.addTo(map);
        return () => {
            map.removeControl(logoControl);
        };
    }, [map]);

    return null;
}

// Component to handle right-click and display popup for feasibility check
function RightClickFeasibility({ onRightClickConfirm }) {
    const [popupPosition, setPopupPosition] = useState(null); // State for popup position
    const [clickedLatLng, setClickedLatLng] = useState(null); // State to store clicked coordinates

    useMapEvents({
        contextmenu(event) {
            const { latlng } = event;
            setPopupPosition(latlng); // Set popup position
            setClickedLatLng(latlng); // Store clicked coordinates
        }
    });

    return popupPosition ? (
        <Popup
            position={popupPosition}
            onClose={() => setPopupPosition(null)} // Close popup if dismissed
        >
            <div style={styles.popupContainer}>
                <p style={styles.popupText}>Would you like to get feasibility at this location?</p>
                <button
                    style={styles.searchButton} // Style the button the same as the search button
                    onClick={() => {
                        onRightClickConfirm(clickedLatLng); // Call the function with clicked coordinates
                        setPopupPosition(null); // Close popup after confirmation
                    }}
                >
                    Get Feasibility
                </button>
            </div>
        </Popup>
    ) : null;
}

function Fibre() {
    const [loading, setLoading] = useState(false); // Loading state for preloader
    const [address, setAddress] = useState('');
    const [coordinates, setCoordinates] = useState(startingOfficeCoords);
    const [latLng, setLatLng] = useState({ lat: -32.90243028852015, lng: 17.99862167001142 }); // Updated coordinates
    const [showModal, setShowModal] = useState(false);
    const [feasibilityResults, setFeasibilityResults] = useState([]);
    const [selectedProviders, setSelectedProviders] = useState(() => {
        const initialSelection = {};
        providersSettings.forEach(provider => {
            initialSelection[provider.capability] = true;
        });
        return initialSelection;
    });
    const [isProviderListOpen, setIsProviderListOpen] = useState(false); // State for collapsing/expanding provider list
    const [quote, setQuote] = useState(""); // State for random quote
    const mapRef = useRef(null);
    const autocompleteRef = useRef(null);
    const markerRef = useRef(null);
    const quoteIntervalRef = useRef(null); // Ref to store interval ID for quotes

    // Ref to hold the latest selectedProviders value
    const selectedProvidersRef = useRef(selectedProviders);

    useEffect(() => {
        selectedProvidersRef.current = selectedProviders;
    }, [selectedProviders]);

    useEffect(() => {
        loadGoogleMapsScript();
        checkMapHeight();
        window.addEventListener('resize', checkMapHeight);
        return () => window.removeEventListener('resize', checkMapHeight);
    }, []);

    const checkMapHeight = () => {
        const topBarElement = document.getElementById('top-bar');
        const mapElement = document.getElementById('map-container');
        if (topBarElement && mapElement) {
            const topBarHeight = topBarElement.offsetHeight;
            const availableHeight = window.innerHeight - topBarHeight;
            mapElement.style.height = `${availableHeight}px`;
        }
    };

    const loadGoogleMapsScript = () => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAuC39UoplUhw-bFf1kcBegnm7j5RUvHhg&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = initAutocomplete;
        document.head.appendChild(script);
    };

    const initAutocomplete = () => {
        if (autocompleteRef.current) {
            const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current);
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (place.geometry) {
                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();
                    setCoordinates(`${lat},${lng}`);
                    setLatLng({ lat, lng });
                    setAddress(place.formatted_address);
                    checkFeasibility(lat, lng);
                    logSearch(`${lat},${lng}`, 'Fibre');
                }
            });
        }
    };

    const logSearch = async (coordinates, searchType) => {
        try {
            const response = await axios.post('/api/log-search', {
                searchType,
                coordinates
            });
            if (response.status === 200) {
                console.log('Search logged successfully');
            } else {
                console.error('Failed to log search:', response.statusText);
            }
        } catch (error) {
            console.error('Error logging search:', error.response ? error.response.data : error.message);
        }
    };

    const handleSearch = () => {
        const [lat, lng] = coordinates.split(',').map(coord => parseFloat(coord.trim()));
        if (!isNaN(lat) && !isNaN(lng)) {
            setLatLng({ lat, lng });
            checkFeasibility(lat, lng);
            logSearch(`${lat},${lng}`, 'Fibre');
        } else {
            alert('Please enter valid coordinates.');
        }
    };

    const getRandomQuote = () => {
        const randomIndex = Math.floor(Math.random() * loadingQuotes.length);
        return loadingQuotes[randomIndex];
    };

    const startQuoteRotation = () => {
        setQuote(getRandomQuote()); // Show a quote immediately
        quoteIntervalRef.current = setInterval(() => {
            setQuote(getRandomQuote()); // Update quote every 5 seconds
        }, 5000);
    };

    const stopQuoteRotation = () => {
        clearInterval(quoteIntervalRef.current); // Stop rotating quotes when loading ends
    };

    const checkFeasibility = async (lat, lng) => {
        setLoading(true);
        startQuoteRotation();
        const results = [];
      
        const currentSelectedProviders = selectedProvidersRef.current;
        const selectedProvidersArray = Object.keys(currentSelectedProviders).filter(key => currentSelectedProviders[key]);
      
        for (const provider of providersSettings) {
          if (selectedProvidersArray.includes(provider.capability)) {
            try {
              // Determine if the provider is one of the new Comsol products
              const isNewComsolProduct = ['Comsol Wireless', 'Comsol Bundle', 'Comsol CX5', 'Comsol Max Bandwidth'].includes(provider.capability);
              
              let response;
              if (isNewComsolProduct) {
                // For new Comsol products, make a POST request with additional parameters
                const requestData = {
                  latitude: lat,
                  longitude: lng,
                };
    
                if (provider.capability === 'Comsol Wireless') {
                  requestData.bandwidth = 50;
                  requestData.cpe_height = 6;
                  requestData.sla_type = 1;
                } else if (provider.capability === 'Comsol Bundle') {
                  requestData.bandwidth = 50;
                  requestData.wait_for_arc = false;
                } else if (provider.capability === 'Comsol CX5') {
                  // No additional parameters needed
                } else if (provider.capability === 'Comsol Max Bandwidth') {
                  requestData.bandwidths = [100, 80, 60, 50, 40, 30, 20, 10, 5, 200];
                  requestData.cpe_height = 6;
                  requestData.sla_type = 1;
                }
    
                response = await axios.post('/api/proxy-feasibility', {
                  ...requestData,
                  capability: provider.capability,
                });
              } else {
                // For other providers, make a GET request
                response = await axios.get('/api/proxy-feasibility', {
                  params: {
                    gpsCoords: `${lat},${lng}`,
                    capability: provider.capability,
                  }
                });
              }
      
              let feasible = false;
              let capacity = null;
              let message = '';
      
              // Handle responses based on provider capability
              if (['DFA', 'MFN', 'Liquid', 'OpenServe', 'Link Africa'].includes(provider.capability)) {
                feasible = response.data.feasible;
                capacity = response.data.medium || response.data.solution || '';
              } else if (provider.name.includes('Frogfoot')) {
                // Handle Frogfoot response
                if (response.data.result && response.data.result.length > 0) {
                  feasible = response.data.result[0].feasible;
                }
              } else if (provider.name.includes('MTN')) {
                // Handle MTN response
                if (response.data.outputs && response.data.outputs.length > 0) {
                  const productResult = response.data.outputs[0].product_results[0];
                  feasible = productResult.product_feasible === 'Yes';
                  capacity = productResult.product_capacity;
                }
              } else if (provider.capability === 'Comsol Wireless' || provider.capability === 'Comsol Bundle') {
                feasible = response.data.results[0].solution !== '';
                message = response.data.results[0].message;
              } else if (provider.capability === 'Comsol CX5') {
                feasible = response.data.results[0].feasible;
                message = response.data.results[0].message;
              } else if (provider.capability === 'Comsol Max Bandwidth') {
                feasible = response.data['CX'].feasible || response.data['CX+'].feasible;
                capacity = Math.max(response.data['CX'].max_bw, response.data['CX+'].max_bw);
                message = response.data['CX'].message || response.data['CX+'].message;
              }
      
              results.push({
                provider: provider.name,
                result: { feasible, capacity, message },
                logo: provider.logo,
              });
      
            } catch (error) {
              console.error(`Error checking feasibility for ${provider.name}:`, error);
              results.push({
                provider: provider.name,
                result: { feasible: false },
                logo: provider.logo,
              });
            }
          }
        }
      
        setFeasibilityResults(results);
        setShowModal(true);
        setLoading(false);
        stopQuoteRotation();
      };
      

    // Updated handleProviderChange function using functional setState
    const handleProviderChange = (providerCapability) => {
        setSelectedProviders(prevState => {
            const newState = {
                ...prevState,
                [providerCapability]: !prevState[providerCapability]
            };
            console.log('Updated selectedProviders:', newState);
            return newState;
        });
    };

    const handleRightClickConfirm = (latLng) => {
        setLatLng(latLng); // Update state with the right-clicked coordinates
        setCoordinates(`${latLng.lat},${latLng.lng}`); // Update the coordinates in the input field
        checkFeasibility(latLng.lat, latLng.lng);
    };

    const closeModal = () => setShowModal(false);

    const officeMarkerIcon = new L.Icon({
        iconUrl: startingOfficeLogo,
        iconSize: [25, 38],
        iconAnchor: [12, 38],
    });

    return (
        <div style={styles.pageContainer}>
            <Top />
            <div id="map-container" style={styles.mapWrapper}>
                <MapContainer
                    center={[latLng.lat, latLng.lng]}
                    zoom={11} // Updated zoom level to 11
                    scrollWheelZoom={true}
                    style={styles.mapContainer}
                    whenCreated={(mapInstance) => (mapRef.current = mapInstance)}
                    attributionControl={false}
                >
                    <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" />
                    <Marker position={[latLng.lat, latLng.lng]} icon={officeMarkerIcon} ref={markerRef} />
                    <UpdateMapCenter latLng={latLng} />
                    <CustomAttribution />
                    <RightClickFeasibility onRightClickConfirm={handleRightClickConfirm} /> {/* Handle right-click confirmation */}
                    <div style={styles.searchContainer}>
                        <input
                            ref={autocompleteRef}
                            type="text"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            placeholder="Enter address or coordinates"
                            style={styles.inputBox}
                        />
                        <p style={styles.rightClickInstructions}>
                            Enter an address, coordinates or right click on the location you would like to search.
                        </p> {/* Updated instructions */}
                        <div style={styles.providerSettingsContainer}>
                            <div style={styles.providerToggle} onClick={() => setIsProviderListOpen(!isProviderListOpen)}>
                                <FaCog style={styles.settingsIcon} />
                                <span>Select Providers</span>
                            </div>
                            <div
                                style={{
                                    ...styles.providerContainer,
                                    height: isProviderListOpen ? 'auto' : '0px',
                                    overflow: isProviderListOpen ? 'visible' : 'hidden',
                                    transition: 'height 0.3s ease-out',
                                }}
                            >
                                <div style={styles.providerGrid}>
                                    {providersSettings.map((provider, index) => (
                                        <div style={styles.providerOption} key={index}>
                                            <img src={provider.logo} alt={provider.name} style={styles.providerLogo} />
                                            <input
                                                type="checkbox"
                                                checked={selectedProviders[provider.capability] || false}
                                                onChange={() => handleProviderChange(provider.capability)}
                                            />
                                            <span>{provider.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <button style={styles.searchButton} onClick={handleSearch}>
                            Search
                        </button>
                    </div>
                </MapContainer>
            </div>

            {loading && (
                <div className="loader-overlay" style={styles.loaderContainer}>
                    <div className="loader"></div>
                    <p style={styles.loadingQuote}>{quote}</p> {/* Display the random quote **below** the loader */}
                </div>
            )}

            {showModal && feasibilityResults.length > 0 && (
                <div className="modal-overlay" onClick={closeModal} style={styles.modalOverlay}>
                    <div className="modal-content" style={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                        <h2 style={styles.modalHeader}>Feasibility Results</h2> {/* Updated header */}
                        <div style={styles.modalResultsContainer}>
                            {feasibilityResults.map((result, index) => (
                                <div key={index} style={styles.modalResult}>
                                    <img src={result.logo} alt={result.provider} style={styles.modalLogo} />
                                    <div style={styles.providerInfo}>
                                        <span style={styles.providerName}>
                                            {result.provider}
                                        </span>
                                        {result.result.feasible ? (
                                            <span style={styles.providerCapacity}>
                                                {result.result.capacity ? `${result.result.capacity} Mb Available` : result.result.message}
                                            </span>
                                        ) : (
                                            <span style={styles.providerCapacity}>
                                                {result.result.message || 'Not Feasible'}
                                            </span>
                                        )}
                                    </div>
                                    {result.result.feasible ? (
                                        <LuCheckCircle style={{ ...styles.successIcon, fontSize: '1.2em' }} />
                                    ) : (
                                        <GoXCircle style={{ ...styles.errorIcon, fontSize: '1.2em' }} />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

const styles = {
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    mapWrapper: {
        flex: 1,
        position: 'relative',
    },
    mapContainer: {
        height: '100%',
        width: '100%',
    },
    searchContainer: {
        position: 'absolute',
        top: '10px',
        left: '10px',
        zIndex: 1000,
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '5px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
    },
    inputBox: {
        width: '250px',
        padding: '5px',
        marginRight: '10px',
        border: '1px solid #ced4da',
        borderRadius: '4px',
    },
    rightClickInstructions: {
        fontSize: '0.8em',
        color: '#6c757d',
        marginTop: '5px',
    },
    providerSettingsContainer: {
        marginTop: '10px',
    },
    providerToggle: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        paddingBottom: '5px',
    },
    settingsIcon: {
        marginRight: '5px',
    },
    providerContainer: {
        transition: 'height 0.3s ease-out',
    },
    providerGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)', // Two-column layout
        gap: '10px',
    },
    providerOption: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px',
    },
    providerLogo: {
        width: '20px',
        height: '20px',
        marginRight: '8px',
        borderRadius: '8px',
    },
    searchButton: {
        marginTop: '10px',
        padding: '5px 10px',
        backgroundColor: '#61acb4', // Updated to match your color
        color: 'white',
        border: 'none',
        borderRadius: '3px',
        cursor: 'pointer',
    },
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10000,
    },
    modalContent: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        width: '60%', // Updated width to 60%
        height: '60%', // Updated height to 60%
        maxHeight: '60vh',
        overflowY: 'auto', // Enable vertical scrolling if needed
        border: '1px solid slategray', // Corrected the modal border issue
        borderRadius: '6px', // Modal border-radius
        boxSizing: 'border-box',
    },
    modalHeader: {
        marginBottom: '15px',
        fontSize: '1.5em',
    },
    modalResultsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    modalResult: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        width: '45%',
        border: '1px solid slategray', // Border for each result
        padding: '8px',
        borderRadius: '6px',
    },
    modalLogo: {
        width: '40px',
        height: '40px',
        marginRight: '10px',
        borderRadius: '6px',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    },
    providerInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    providerName: {
        fontSize: '1em',
    },
    providerCapacity: {
        fontSize: '0.7em',
        marginTop: '5px',
    },
    successIcon: {
        color: 'green',
        marginLeft: 'auto',
        fontSize: '1.2em',
    },
    errorIcon: {
        color: 'grey',
        marginLeft: 'auto',
        fontSize: '1.2em',
        opacity: '0.5',
    },
    loaderContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', // Ensure the loader is centered
    },
    loadingQuote: {
        marginTop: '20px', // Fix to display quote **below** loader
        fontSize: '1em',
        textAlign: 'center',
        color: '#333', // Quote text color
    },
    popupContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    popupText: {
        marginBottom: '10px',
    },
};

export default Fibre;
