const providersSettings = [
    // {
    //     name: 'Frogfoot FTTH',
    //     capability: 'FTTH',
    //     logo: './fflogo.png',
    // },
    // {
    //     name: 'Frogfoot FTTB',
    //     capability: 'FTTB',
    //     logo: './fflogo.png',
    // },
    // {
    //     name: 'MTN Fixed Wireless Broadband',
    //     capability: 'Fixed Wireless Broadband', // Full product name
    //     logo: './mtnlogo.png',
    // },
    // {
    //     name: 'MTN Wholesale Cloud Connect',
    //     capability: 'Wholesale Cloud Connect', // Full product name
    //     logo: './mtnlogo.png',
    // },
    // {
    //     name: 'MTN Wholesale Cloud Connect Lite',
    //     capability: 'Wholesale Cloud Connect Lite', // Full product name
    //     logo: './mtnlogo.png',
    // },
    // {
    //     name: 'MTN Wholesale Ethernet Wave Leased Line',
    //     capability: 'Wholesale Ethernet Wave Leased Line', // Full product name
    //     logo: './mtnlogo.png',
    // },
    // {
    //     name: 'MTN Wholesale FTTH (Consumer)',
    //     capability: 'Wholesale FTTH (Consumer)', // Full product name
    //     logo: './mtnlogo.png',
    // },
    // {
    //     name: 'MTN Wholesale Access Connect',
    //     capability: 'Wholesale Access Connect', // Full product name
    //     logo: './mtnlogo.png',
    // },
    // New provider: Comsol
    // {
    //     name: 'DFA',
    //     capability: 'DFA',
    //     logo: './dfalogo.png',
    //   },
    //   {
    //     name: 'MetroFibre',
    //     capability: 'MFN',
    //     logo: './metrofibrelogo.png',
    //   },
    //   {
    //     name: 'Liquid Telecom',
    //     capability: 'Liquid',
    //     logo: './liquidlogo.png',
    //   },
      {
        name: 'OpenServe',
        capability: 'OpenServe', // Corrected to match the API response
        logo: './openservelogo.png',
      },
      // {
      //   name: 'Comsol Wireless',
      //   capability: 'Comsol Wireless',
      //   logo: './comsollogo.png',
      // },
      // {
      //   name: 'Comsol Bundle',
      //   capability: 'Comsol Bundle',
      //   logo: './comsollogo.png',
      // },
      // {
      //   name: 'Comsol CX5',
      //   capability: 'Comsol CX5',
      //   logo: './comsollogo.png',
      // },
      // {
      //   name: 'Comsol Max Bandwidth',
      //   capability: 'Comsol Max Bandwidth',
      //   logo: './comsollogo.png',
      // },

    ];

// List of quotes to display during loading
export const loadingQuotes = [
  "Loading your results...",
  "Calculating feasibility...",
  "Analyzing data...",
  "Please wait a moment...",
  "I can see my house from up here...",
  "Processing your request...",
  "Loading your future network... one pixel at a time",
  "Theres no place like 127.0.0.1",
];

export default providersSettings;
