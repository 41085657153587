// import React, { useState, useEffect } from 'react';
// import Top from './Top';

// function Towers() {
//     const [selectedFile, setSelectedFile] = useState(null);
//     const [basePoints, setBasePoints] = useState([]);
//     const [editPoint, setEditPoint] = useState(null);
//     const [newPoint, setNewPoint] = useState({
//         name: '',
//         latitude: '',
//         longitude: '',
//         height: 30,
//         sector_angle: 360,
//         azimuth: 0,
//         range: 10000,
//     });
//     const [message, setMessage] = useState('');

//     useEffect(() => {
//         // Fetch base points on component mount
//         fetchBasePoints();
//     }, []);

//     const fetchBasePoints = async () => {
//         try {
//             const response = await fetch('/api/get-base-points');
//             const data = await response.json();
//             setBasePoints(data);
//         } catch (error) {
//             console.error('Error fetching base points:', error);
//             setMessage('Error fetching base points.');
//         }
//     };

//     const handleEditPoint = (point) => {
//         setEditPoint(point);
//     };

//     const handleSaveEdit = async (id) => {
//         try {
//             const response = await fetch(`/api/edit-base-point/${id}`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(editPoint),
//             });

//             if (response.ok) {
//                 const result = await response.json();
//                 setMessage(result.message);
//                 setEditPoint(null); // Reset edit state after saving
//                 fetchBasePoints(); // Refresh points after update
//             } else {
//                 const errorText = await response.text();
//                 setMessage(`Error: ${errorText}`);
//             }
//         } catch (error) {
//             console.error('Error saving base point:', error);
//             setMessage('Error saving base point.');
//         }
//     };

//     const handleDeletePoint = async (id) => {
//         if (!window.confirm('Are you sure you want to delete this base point?')) {
//             return;  // Cancel the deletion if the user clicks "Cancel"
//         }

//         try {
//             const response = await fetch(`/api/delete-base-point/${id}`, {
//                 method: 'DELETE',
//             });

//             if (response.ok) {
//                 const result = await response.json();
//                 setMessage(result.message);
//                 fetchBasePoints(); // Refresh the base points after deletion
//             } else {
//                 const errorText = await response.text();
//                 setMessage(`Error: ${errorText}`);
//             }
//         } catch (error) {
//             console.error('Error deleting base point:', error);
//             setMessage('Error deleting base point.');
//         }
//     };

//     const handleAddPoint = async () => {
//         try {
//             const { name, latitude, longitude, height, sector_angle, azimuth, range } = newPoint;

//             if (!name || !latitude || !longitude) {
//                 setMessage('Please fill in all required fields.');
//                 return;
//             }

//             const response = await fetch('/api/add-base-point', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(newPoint),
//             });

//             if (response.ok) {
//                 const result = await response.json();
//                 setMessage(result.message);
//                 setNewPoint({
//                     name: '',
//                     latitude: '',
//                     longitude: '',
//                     height: 30,
//                     sector_angle: 360,
//                     azimuth: 0,
//                     range: 10000,
//                 }); // Reset fields
//                 fetchBasePoints(); // Refresh points after adding
//             } else {
//                 const errorText = await response.text();
//                 setMessage(`Error: ${errorText}`);
//             }
//         } catch (error) {
//             console.error('Error adding base point:', error);
//             setMessage('Error adding base point.');
//         }
//     };

//     const handleChange = (e) => {
//         setEditPoint({
//             ...editPoint,
//             [e.target.name]: e.target.value,
//         });
//     };

//     const handleNewPointChange = (e) => {
//         setNewPoint({
//             ...newPoint,
//             [e.target.name]: e.target.value,
//         });
//     };

//     // Generate sector angles in 15-degree increments
//     const sectorAngles = [];
//     for (let angle = 15; angle <= 360; angle += 15) {
//         sectorAngles.push(angle);
//     }

//     return (
//         <div style={styles.pageContainer}>
//             <Top />
//             <div style={styles.container}>
//                 {/* Expanded Right Section for Displaying Base Points */}
//                 <div style={{ ...styles.rightSection, flexBasis: '100%' }}>
//                     <h3 style={styles.heading}>Base Points</h3>
//                     {message && <p>{message}</p>}
//                     <table style={styles.table}>
//                         <thead>
//                             <tr>
//                                 <th style={styles.th}>Name</th>
//                                 <th style={styles.th}>Latitude</th>
//                                 <th style={styles.th}>Longitude</th>
//                                 <th style={styles.th}>Height</th>
//                                 <th style={styles.th}>Sector Angle</th>
//                                 <th style={styles.th}>Azimuth</th>
//                                 <th style={styles.th}>Range</th>
//                                 <th style={styles.th}>Actions</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {basePoints.map((point) => (
//                                 <tr key={point.id}>
//                                     {editPoint && editPoint.id === point.id ? (
//                                         <>
//                                             <td>
//                                                 <input
//                                                     type="text"
//                                                     name="name"
//                                                     value={editPoint.name}
//                                                     onChange={handleChange}
//                                                     style={styles.input}
//                                                 />
//                                             </td>
//                                             <td>
//                                                 <input
//                                                     type="text"
//                                                     name="latitude"
//                                                     value={editPoint.latitude}
//                                                     onChange={handleChange}
//                                                     style={styles.input}
//                                                 />
//                                             </td>
//                                             <td>
//                                                 <input
//                                                     type="text"
//                                                     name="longitude"
//                                                     value={editPoint.longitude}
//                                                     onChange={handleChange}
//                                                     style={styles.input}
//                                                 />
//                                             </td>
//                                             <td>
//                                                 <input
//                                                     type="text"
//                                                     name="height"
//                                                     value={editPoint.height}
//                                                     onChange={handleChange}
//                                                     style={styles.input}
//                                                 />
//                                             </td>
//                                             <td>
//                                                 <select
//                                                     name="sector_angle"
//                                                     value={editPoint.sector_angle}
//                                                     onChange={handleChange}
//                                                     style={styles.select}
//                                                 >
//                                                     {sectorAngles.map((angle) => (
//                                                         <option key={angle} value={angle}>
//                                                             {angle}°
//                                                         </option>
//                                                     ))}
//                                                 </select>
//                                             </td>
//                                             <td>
//                                                 <input
//                                                     type="number"
//                                                     name="azimuth"
//                                                     value={editPoint.azimuth}
//                                                     onChange={handleChange}
//                                                     style={styles.input}
//                                                     min="0"
//                                                     max="360"
//                                                 />
//                                             </td>
//                                             <td>
//                                                 <input
//                                                     type="number"
//                                                     name="range"
//                                                     value={editPoint.range}
//                                                     onChange={handleChange}
//                                                     style={styles.input}
//                                                 />
//                                             </td>
//                                             <td>
//                                                 <button onClick={() => handleSaveEdit(point.id)} style={styles.saveButton}>
//                                                     Save
//                                                 </button>
//                                             </td>
//                                         </>
//                                     ) : (
//                                         <>
//                                             <td style={styles.td}>{point.name}</td>
//                                             <td style={styles.td}>{point.latitude}</td>
//                                             <td style={styles.td}>{point.longitude}</td>
//                                             <td style={styles.td}>{point.height}</td>
//                                             <td style={styles.td}>{point.sector_angle}°</td>
//                                             <td style={styles.td}>{point.azimuth}°</td>
//                                             <td style={styles.td}>{point.range} m</td>
//                                             <td style={styles.td}>
//                                                 <button onClick={() => handleEditPoint(point)} style={styles.editButton}>
//                                                     Edit
//                                                 </button>
//                                                 <button onClick={() => handleDeletePoint(point.id)} style={styles.deleteButton}>
//                                                     Delete
//                                                 </button>
//                                             </td>
//                                         </>
//                                     )}
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>

//                     {/* Add New Base Point */}
//                     <h3>Add New Base Point</h3>
//                     <div style={styles.formRow}>
//                         <input
//                             type="text"
//                             placeholder="Name"
//                             name="name"
//                             value={newPoint.name}
//                             onChange={handleNewPointChange}
//                             style={styles.input}
//                         />
//                         <input
//                             type="text"
//                             placeholder="Latitude"
//                             name="latitude"
//                             value={newPoint.latitude}
//                             onChange={handleNewPointChange}
//                             style={styles.input}
//                         />
//                         <input
//                             type="text"
//                             placeholder="Longitude"
//                             name="longitude"
//                             value={newPoint.longitude}
//                             onChange={handleNewPointChange}
//                             style={styles.input}
//                         />
//                     </div>
//                     <div style={styles.formRow}>
//                         <input
//                             type="text"
//                             placeholder="Height"
//                             name="height"
//                             value={newPoint.height}
//                             onChange={handleNewPointChange}
//                             style={styles.input}
//                         />
//                         <select
//                             name="sector_angle"
//                             value={newPoint.sector_angle}
//                             onChange={handleNewPointChange}
//                             style={styles.select}
//                         >
//                             {sectorAngles.map((angle) => (
//                                 <option key={angle} value={angle}>
//                                     {angle}°
//                                 </option>
//                             ))}
//                         </select>
//                         <input
//                             type="number"
//                             placeholder="Azimuth"
//                             name="azimuth"
//                             value={newPoint.azimuth}
//                             onChange={handleNewPointChange}
//                             style={styles.input}
//                             min="0"
//                             max="360"
//                         />
//                         <input
//                             type="number"
//                             placeholder="Range (meters)"
//                             name="range"
//                             value={newPoint.range}
//                             onChange={handleNewPointChange}
//                             style={styles.input}
//                         />
//                     </div>
//                     <button onClick={handleAddPoint} style={styles.addButton}>
//                         Add Point
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// }

// const styles = {
//     pageContainer: {
//         height: '100vh',
//         display: 'flex',
//         flexDirection: 'column',
//         overflow: 'hidden',
//     },
//     container: {
//         display: 'flex',
//         flexGrow: 1,
//         margin: '5%',
//         border: '1px solid slategray',
//         borderRadius: '12px',
//         overflow: 'hidden',
//         height: 'calc(100vh - 20vh - 10%)',
//     },
//     rightSection: {
//         flexBasis: '75%',
//         paddingLeft: '20px',
//         overflowY: 'auto',
//         maxHeight: '100%',
//     },
//     heading: {
//         fontSize: '18px',
//         color: '#2c3e50',
//         marginBottom: '15px',
//     },
//     table: {
//         width: '100%',
//         borderCollapse: 'collapse',
//         marginBottom: '20px',
//     },
//     th: {
//         backgroundColor: '#f2f2f2',
//         borderBottom: '1px solid slategray',
//         padding: '10px',
//     },
//     td: {
//         borderBottom: '1px solid slategray',
//         padding: '10px',
//         textAlign: 'center',
//     },
//     input: {
//         padding: '8px',
//         margin: '5px',
//         border: '1px solid #61acb4',
//         borderRadius: '4px',
//     },
//     select: {
//         padding: '8px',
//         margin: '5px',
//         border: '1px solid #61acb4',
//         borderRadius: '4px',
//     },
//     formRow: {
//         display: 'flex',
//         flexWrap: 'wrap',
//         marginBottom: '10px',
//     },
//     addButton: {
//         backgroundColor: '#61acb4',
//         color: '#fff',
//         padding: '10px 20px',
//         border: 'none',
//         borderRadius: '4px',
//         cursor: 'pointer',
//     },
//     editButton: {
//         backgroundColor: '#61acb4',
//         color: '#fff',
//         padding: '5px 10px',
//         border: 'none',
//         borderRadius: '4px',
//         cursor: 'pointer',
//         marginRight: '5px',
//     },
//     saveButton: {
//         backgroundColor: '#5cb85c',
//         color: '#fff',
//         padding: '5px 10px',
//         border: 'none',
//         borderRadius: '4px',
//         cursor: 'pointer',
//     },
//     deleteButton: {
//         backgroundColor: '#d9534f',
//         color: '#fff',
//         padding: '5px 10px',
//         border: 'none',
//         borderRadius: '4px',
//         cursor: 'pointer',
//     },
// };

// export default Towers;
import React, { useState, useEffect } from 'react';
import Top from './Top';

function Towers() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [basePoints, setBasePoints] = useState([]);
    const [editPoint, setEditPoint] = useState(null);
    const [newPoint, setNewPoint] = useState({
        name: '',
        latitude: '',
        longitude: '',
        height: 30,
        sector_angle: 360,
        azimuth: 0,
        range: 10000,
    });
    const [message, setMessage] = useState('');

    useEffect(() => {
        // Fetch base points on component mount
        fetchBasePoints();
    }, []);

    const fetchBasePoints = async () => {
        try {
            const response = await fetch('/api/get-base-points');
            const data = await response.json();
            setBasePoints(data);
        } catch (error) {
            console.error('Error fetching base points:', error);
            setMessage('Error fetching base points.');
        }
    };

    const handleEditPoint = (point) => {
        setEditPoint(point);
    };

    const handleSaveEdit = async (id) => {
        try {
            const response = await fetch(`/api/edit-base-point/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editPoint),
            });

            if (response.ok) {
                const result = await response.json();
                setMessage(result.message);
                setEditPoint(null); // Reset edit state after saving
                fetchBasePoints(); // Refresh points after update
            } else {
                const errorText = await response.text();
                setMessage(`Error: ${errorText}`);
            }
        } catch (error) {
            console.error('Error saving base point:', error);
            setMessage('Error saving base point.');
        }
    };

    const handleDeletePoint = async (id) => {
        if (!window.confirm('Are you sure you want to delete this base point?')) {
            return;  // Cancel the deletion if the user clicks "Cancel"
        }

        try {
            const response = await fetch(`/api/delete-base-point/${id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                const result = await response.json();
                setMessage(result.message);
                fetchBasePoints(); // Refresh the base points after deletion
            } else {
                const errorText = await response.text();
                setMessage(`Error: ${errorText}`);
            }
        } catch (error) {
            console.error('Error deleting base point:', error);
            setMessage('Error deleting base point.');
        }
    };

    const handleAddPoint = async () => {
        try {
            const { name, latitude, longitude, height, sector_angle, azimuth, range } = newPoint;

            if (!name || !latitude || !longitude) {
                setMessage('Please fill in all required fields.');
                return;
            }

            const response = await fetch('/api/add-base-point', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newPoint),
            });

            if (response.ok) {
                const result = await response.json();
                setMessage(result.message);
                setNewPoint({
                    name: '',
                    latitude: '',
                    longitude: '',
                    height: 30,
                    sector_angle: 360,
                    azimuth: 0,
                    range: 10000,
                }); // Reset fields
                fetchBasePoints(); // Refresh points after adding
            } else {
                const errorText = await response.text();
                setMessage(`Error: ${errorText}`);
            }
        } catch (error) {
            console.error('Error adding base point:', error);
            setMessage('Error adding base point.');
        }
    };

    const handleChange = (e) => {
        setEditPoint({
            ...editPoint,
            [e.target.name]: e.target.value,
        });
    };

    const handleNewPointChange = (e) => {
        setNewPoint({
            ...newPoint,
            [e.target.name]: e.target.value,
        });
    };

    // Generate sector angles in 15-degree increments
    const sectorAngles = [];
    for (let angle = 15; angle <= 360; angle += 15) {
        sectorAngles.push(angle);
    }

    return (
        <div style={styles.pageContainer}>
            <Top />
            <div style={styles.container}>
                {/* Expanded Right Section for Displaying Base Points */}
                <div style={{ ...styles.rightSection, flexBasis: '100%' }}>
                    <h3 style={styles.heading}>Base Points</h3>
                    {message && <p>{message}</p>}
                    <table style={styles.table}>
                        <thead>
                            <tr>
                                <th style={styles.th}>Name</th>
                                <th style={styles.th}>Latitude</th>
                                <th style={styles.th}>Longitude</th>
                                <th style={styles.th}>Height AGL</th>
                                <th style={styles.th}>Sector Angle</th>
                                <th style={styles.th}>Azimuth</th>
                                <th style={styles.th}>Range</th>
                                <th style={styles.th}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {basePoints.map((point) => (
                                <tr key={point.id}>
                                    {editPoint && editPoint.id === point.id ? (
                                        <>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={editPoint.name}
                                                    onChange={handleChange}
                                                    style={styles.input}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="latitude"
                                                    value={editPoint.latitude}
                                                    onChange={handleChange}
                                                    style={styles.input}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="longitude"
                                                    value={editPoint.longitude}
                                                    onChange={handleChange}
                                                    style={styles.input}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="height"
                                                    value={editPoint.height}
                                                    onChange={handleChange}
                                                    style={styles.input}
                                                />
                                            </td>
                                            <td>
                                                <select
                                                    name="sector_angle"
                                                    value={editPoint.sector_angle}
                                                    onChange={handleChange}
                                                    style={styles.select}
                                                >
                                                    {sectorAngles.map((angle) => (
                                                        <option key={angle} value={angle}>
                                                            {angle}°
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    name="azimuth"
                                                    value={editPoint.azimuth}
                                                    onChange={handleChange}
                                                    style={styles.input}
                                                    min="0"
                                                    max="360"
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    name="range"
                                                    value={editPoint.range}
                                                    onChange={handleChange}
                                                    style={styles.input}
                                                />
                                            </td>
                                            <td>
                                                <button onClick={() => handleSaveEdit(point.id)} style={styles.saveButton}>
                                                    Save
                                                </button>
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <td style={styles.td}>{point.name}</td>
                                            <td style={styles.td}>{point.latitude}</td>
                                            <td style={styles.td}>{point.longitude}</td>
                                            <td style={styles.td}>{point.height}</td>
                                            <td style={styles.td}>{point.sector_angle}°</td>
                                            <td style={styles.td}>{point.azimuth}°</td>
                                            <td style={styles.td}>{point.range} m</td>
                                            <td style={styles.td}>
                                                <button onClick={() => handleEditPoint(point)} style={styles.editButton}>
                                                    Edit
                                                </button>
                                                <button onClick={() => handleDeletePoint(point.id)} style={styles.deleteButton}>
                                                    Delete
                                                </button>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Add New Base Point */}
                    <h3>Add New Base Point</h3>
                    <div style={styles.formContainer}>
                        <div style={styles.column}>
                            <div style={styles.formGroup}>
                                <label style={styles.label} htmlFor="name">
                                    Name:
                                </label>
                                <input
                                    type="text"
                                    placeholder="Name"
                                    name="name"
                                    id="name"
                                    value={newPoint.name}
                                    onChange={handleNewPointChange}
                                    style={styles.input}
                                />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label} htmlFor="latitude">
                                    Latitude:
                                </label>
                                <input
                                    type="text"
                                    placeholder="Latitude"
                                    name="latitude"
                                    id="latitude"
                                    value={newPoint.latitude}
                                    onChange={handleNewPointChange}
                                    style={styles.input}
                                />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label} htmlFor="longitude">
                                    Longitude:
                                </label>
                                <input
                                    type="text"
                                    placeholder="Longitude"
                                    name="longitude"
                                    id="longitude"
                                    value={newPoint.longitude}
                                    onChange={handleNewPointChange}
                                    style={styles.input}
                                />
                            </div>
                        </div>
                        <div style={styles.column}>
                            <div style={styles.formGroup}>
                                <label style={styles.label} htmlFor="height">
                                    Height AGL:
                                </label>
                                <input
                                    type="text"
                                    placeholder="Height"
                                    name="height"
                                    id="height"
                                    value={newPoint.height}
                                    onChange={handleNewPointChange}
                                    style={styles.input}
                                />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label} htmlFor="sector_angle">
                                    Sector Angle:
                                </label>
                                <select
                                    name="sector_angle"
                                    id="sector_angle"
                                    value={newPoint.sector_angle}
                                    onChange={handleNewPointChange}
                                    style={styles.select}
                                >
                                    {sectorAngles.map((angle) => (
                                        <option key={angle} value={angle}>
                                            {angle}°
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label} htmlFor="azimuth">
                                    Azimuth:
                                </label>
                                <input
                                    type="number"
                                    placeholder="Azimuth"
                                    name="azimuth"
                                    id="azimuth"
                                    value={newPoint.azimuth}
                                    onChange={handleNewPointChange}
                                    style={styles.input}
                                    min="0"
                                    max="360"
                                />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label} htmlFor="range">
                                    Range (meters):
                                </label>
                                <input
                                    type="number"
                                    placeholder="Range (meters)"
                                    name="range"
                                    id="range"
                                    value={newPoint.range}
                                    onChange={handleNewPointChange}
                                    style={styles.input}
                                />
                            </div>
                        </div>
                    </div>
                    <button onClick={handleAddPoint} style={styles.addButton}>
                        Add Point
                    </button>
                </div>
            </div>
        </div>
    );
}

const styles = {
    pageContainer: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    container: {
        display: 'flex',
        flexGrow: 1,
        margin: '5%',
        border: '1px solid slategray',
        borderRadius: '12px',
        overflow: 'hidden',
        height: 'calc(100vh - 20vh - 10%)',
    },
    rightSection: {
        flexBasis: '75%',
        paddingLeft: '20px',
        overflowY: 'auto',
        maxHeight: '100%',
    },
    heading: {
        fontSize: '18px',
        color: '#2c3e50',
        marginBottom: '15px',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginBottom: '20px',
    },
    th: {
        backgroundColor: '#f2f2f2',
        borderBottom: '1px solid slategray',
        padding: '10px',
    },
    td: {
        borderBottom: '1px solid slategray',
        padding: '10px',
        textAlign: 'center',
    },
    formContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    column: {
        flexBasis: '45%',
    },
    formGroup: {
        marginBottom: '15px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
    },
    input: {
        padding: '8px',
        marginBottom: '5px',
        border: '1px solid #61acb4',
        borderRadius: '4px',
        width: '100%',
    },
    select: {
        padding: '8px',
        marginBottom: '5px',
        border: '1px solid #61acb4',
        borderRadius: '4px',
        width: '100%',
    },
    addButton: {
        backgroundColor: '#61acb4',
        color: '#fff',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '10px',
    },
    editButton: {
        backgroundColor: '#61acb4',
        color: '#fff',
        padding: '5px 10px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginRight: '5px',
    },
    saveButton: {
        backgroundColor: '#5cb85c',
        color: '#fff',
        padding: '5px 10px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    deleteButton: {
        backgroundColor: '#d9534f',
        color: '#fff',
        padding: '5px 10px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
};

export default Towers;
